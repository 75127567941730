import { ReactComponent as Pencil } from "./Pencil.svg";

const PencilIcon = () => {
  return (
    <>
      <Pencil />
    </>
  );
};

export default PencilIcon;
