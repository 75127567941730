
import Buttons from "../../../../component/buttons/Buttons"
import style from "./style.module.scss"
import Locale from "../../../../component/locale/Locale";
import {useCurrentLocale} from "../../../../lib/function";

const Join = () => {
  const currentLocale = useCurrentLocale();
  return <section className={style.joinWrap}>
    <div className={style.joinHeader}>
      Join the Team
    </div>
    <div className={style.descWrap}>
      <div className={currentLocale === "ko" ? style.desc1Ko : style.desc1}>
        <Locale tag="nftm_main_join_desc1"/>
      </div>
      <div className={currentLocale === "ko" ? style.desc2Ko : style.desc2}>
       <Locale tag="nftm_main_join_desc2"/>
      </div>
    </div>
    <div className={style.buttonWrap}>
      <Buttons style={{width: "156px", height: "43px"}} onClick={() => {
      window.open("https://www.bitblue.team/hiring")}
      }>
      <Locale tag="nftm_main_join_button_text"/>
      </Buttons>
    </div>
  </section>

  
}

export default Join