import React from "react";
import FacebookIcon from "../../Icon/snsIcon/facebook/FacebookIcon";
import InstagramIcon from "../../Icon/snsIcon/instagram/InstagramIcon";
import TwitterIcon from "../../Icon/snsIcon/twitter/TwitterIcon";
import YoutubeIcon from "../../Icon/snsIcon/youtube/YoutubeIcon";
import { AppProps } from "../../Type/type";
import MobileFooter from "./MobileFooter/MobileFooter";
import style from "./style.module.scss";
import WebFooter from "./WebFooter/WebFooter";

const Footer = (props: AppProps) => {
  const iconArray = [
    <YoutubeIcon />,
    <InstagramIcon />,
    <TwitterIcon />,
    <FacebookIcon />,
  ];
  const isPrefixRenderer = () => {
    if (props.prefix) {
      switch (props.prefix) {
        case "xxl":
          return <WebFooter />;
        case "xl":
          return <WebFooter />;
        case "md":
          return <MobileFooter />;
        case "sm":
          return <MobileFooter />;

        default:
          return <WebFooter />;
      }
    }
  };

  return (
   <>
   {isPrefixRenderer()}
   </>
  );
};

export default Footer;
