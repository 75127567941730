import { AppProps } from '../../Type/type';
import {ReactComponent as Bag} from './bagIcon.svg';
import {ReactComponent as MobileBag} from './mobileBagIcon.svg';

const BagIcon = (props: AppProps) => {
    
    return <div>
        {
            props.prefix && props.prefix !== "sm" ? <Bag/> : <MobileBag/>
        }
    </div>
}

export default BagIcon