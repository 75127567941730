import { ReactComponent as FilterIcon } from "./FilterIcon.svg";

const Filter = () => {
  return (
    <>
      <FilterIcon />
    </>
  );
};

export default Filter;
