import { useState, useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { initialState, State } from "../../store";

import { GetLocale } from "../../lib/locale/Locale";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {useCurrentLocale} from "../../lib/function";

interface LocaleProps {
  tag: string;
  params?: Array<string | number>;
  content?: (text: string) => JSX.Element;
  html?: boolean;
  color?: string;
}

const Locale: FC<LocaleProps> = ({ tag, params, content, html, color }) => {
  const [localeData, setLocaleData] = useState<{
    [key: string]: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const currentLocale = useCurrentLocale()

  useEffect(() => {
    const fetchLocaleData = async () => {
      setLoading(true);
      try {
        const data = await GetLocale(currentLocale);
        setLocaleData(data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    if (currentLocale) {
      fetchLocaleData()
    }

  }, [currentLocale]);


  function replaceParams(text: string, ...params: any[]): string {
    if (params) {
      text = text.replace(/{(\d+)}/g, (_match: string, index: string) => {
        const paramIndex = parseInt(index, 10);
        return params[paramIndex] ?? "";
      });
    }
    return text;
  }

  if (!loading && localeData && localeData[tag]) {
    let text = localeData[tag];
    if (params) {
      text = replaceParams(text, ...params);
    }
    if (content) {
      return content(text);
    }
    if (html || color) {
      return (
        <div
          className={color ? `text-${color}` : ""}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      );
    }
    return <>{text}</>;
  }

  return <span>{tag}</span>;
};

export default Locale;
