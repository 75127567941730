import { ReactComponent as Reload } from "./Reload.svg";

const ReloadIcon = () => {
  return (
    <>
      <Reload />
    </>
  );
};

export default ReloadIcon;
