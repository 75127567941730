import { ReactComponent as DefaultWallet } from "./DefaultWallet.svg";

const DefaultWalletIcon = () => {
  return (
    <>
      <DefaultWallet />
    </>
  );
};

export default DefaultWalletIcon;
