import "./style.scss";
import { classnames } from "../../lib/class";
import CloseIcon from "../../Icon/close/CloseIcon";
import { HeaderProps } from "../../Type/type";

const SideMenu = (props: HeaderProps) => {
  const { sideMenuState } = props;
  const menuArray = ["Services", "Naas", "Company"]

  const moveToMenu = (el: string) => {
    if (props.onModalOpener) props.onModalOpener();
  
    switch (el) {
      case "Services":
        return props.onMoveToService();
      case "Naas":
        return props.onMoveToNaas();
      case "Company":
        return props.onMoveToCompany();
  
      default:
        return props.onMoveToService();
    }
  };

  

  return (
    <section className={classnames("sideMenuWrap", `${sideMenuState}`)}>
      <header className="closeIcon" onClick={props.onModalOpener}>
        <CloseIcon />
      </header>
      <article>
        <div className="ideMenuItems">
          {
            menuArray.map((el, idx) => (
          <div className="menuWrap" key={idx} onClick={() => {
            moveToMenu(el)
          }}>
            <div className="menuName">
              {el}
            </div>
          </div>
            ))
          }
        </div>
      </article>
    </section>
  );
};

export default SideMenu;
