import style from "./style.module.scss";
import { ProjectProps } from "../../../lib/type";

const Project = (props: ProjectProps) => {
  return (
    <article className={style.projectArticle}>
      <div className={style.projectWrap}>
        <div className={style.projectHeader}>
          <div className={style.projectTitle}>프로젝트 조회</div>
          <div className={style.finger}>
            <img
              src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
              alt="finger"
            />
          </div>
          <button
            className={style.projectButton}
            onClick={() => {
              props.setIsContentMenuState &&
                props.setIsContentMenuState("projectRegistration");
              props.contentMenuHandler &&
                props.contentMenuHandler("projectRegistration");
            }}
          >
            프로젝트 등록
          </button>
        </div>
        <div className={style.projectContentCheckList}>
          <img
            src="https://www.nftainment.ai/image/searchGroup.png"
            alt="project"
          />
        </div>
        <div>
          <img
            src="https://www.nftainment.ai/image/projectTableBox.png"
            alt="project"
          />
        </div>
      </div>
    </article>
  );
};

export default Project;
