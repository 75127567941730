import { ReactComponent as RightArrow } from "./RightArrow.svg";

const RightArrowIcon = () => {
  return (
    <>
      <RightArrow />
    </>
  );
};

export default RightArrowIcon;
