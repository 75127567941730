import ProgressBox from "../../../component/progressbox";
import Step1Icon from "../../../Icon/step/step1";
import Step2Icon from "../../../Icon/step/step2";
import Step3Icon from "../../../Icon/step/step3";
import { ProcessProps } from "../../../Type/type";
import style from "./style.module.scss";
import Locale from "../../../component/locale/Locale";
import { useCurrentLocale } from "../../../lib/function";

const WebProcess = (props: ProcessProps) => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.processWrap}>
      <div className={style.processesTopWrap}>
        <div className={style.headerTextWrap}>
          <h1 className={style.processHeader1}>How It Works</h1>
          <h2 className={style.processHeader2}>Our Process</h2>
          <div>
            <p
              className={
                currentLocale === "ko"
                  ? style.processHeader3Ko
                  : style.processHeader3
              }
            >
              <Locale tag="nftm_main5_h3_1" />
            </p>
          <p
            className={
              currentLocale === "ko"
                ? style.processHeader3Ko
                : style.processHeader3
            }
          >
            <Locale tag="nftm_main5_h3_2" />
          </p>
          </div>
        </div>
        <div className={style.progressbarContianer}>
          <div className={style.progressWrap1}>
            <div className={style.progress1}>
              <Step1Icon />
            </div>
            <ProgressBox header="Discover" list={props.discoverList} />
            <ProgressBox header="Define" list={props.defineList} />
          </div>
          <div className={style.progressWrap2}>
            <div className={style.progress2}>
              <Step2Icon />
            </div>
            <ProgressBox header="Build" list={props.buildList} />
          </div>
          <div className={style.progressWrap2}>
            <div className={style.progress3}>
              <Step3Icon />
            </div>
            <ProgressBox header="Scale" list={props.scaleList} />
          </div>
          <div className={style.endCircle}></div>
        </div>
      </div>
      <div className={style.bottomBackground}></div>
    </section>
  );
};

export default WebProcess;
