import style from "./style.module.scss";
import { ProjectProps } from "../../../lib/type";

const CustomerManage = (props: ProjectProps) => {
  const customerManageData = [
    {
      email: "susanna.Lind57@gmail.com",
      wallet: "0x4C2...75bb",
      item: 20,
      recent: "2주 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "estelle.Bailey10@gmail.com",
      wallet: "0x4C2...75bb",
      item: 10,
      recent: "4일 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "milo86@gmail.com",
      wallet: "0x4C2...75bb",
      item: 8,
      recent: "18일 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "lonnie35@gmail.com",
      wallet: "0x4C2...75bb",
      item: 70,
      recent: "1주 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "otho21@gmail.com",
      wallet: "0x4C2...75bb",
      item: 129,
      recent: "10시간 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "roma.Dickens79@gmail.com",
      wallet: "0x4C2...75bb",
      item: 70,
      recent: "8시간 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "florencio.Little@gmail.com",
      wallet: "0x4C2...75bb",
      item: 129,
      recent: "10시간 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "tillman.Gleason68@gmail.com",
      wallet: "0x4C2...75bb",
      item: 356,
      recent: "1시간 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "ahmad_Collins@gmail.com",
      wallet: "0x4C2...75bb",
      item: 3434,
      recent: "1일 전",
      subscription: "11/26/2099 11:18",
    },
    {
      email: "DalilaOuldcott@gmail.com ",
      wallet: "0x4C2...75bb",
      item: 865,
      recent: "20일 전",
      subscription: "11/26/2099 11:18",
    },
  ];
  return (
    <article className={style.customerManage}>
      <img src={process.env.PUBLIC_URL + "/image/customersearch.png"} />
      <div className={style.tableContainer}>
        <div className={style.tableWrap}>
          <div className={style.tableHeader}>회원목록 (총 30건))</div>
          <div className={style.finger}>
            <img src={process.env.PUBLIC_URL + "/image/rightfinger.png"} />
          </div>
          <div className={style.tableItemContainer}>
            {customerManageData.map((el, index) => (
              <div
                className={style.tableItemWrap}
                onClick={() => {
                  if (index === 0) {
                    props.setIsContentMenuState &&
                      props.setIsContentMenuState("customerdetail");
                    props.contentMenuHandler &&
                      props.contentMenuHandler("customerdetail");
                  }
                }}
              >
                <div className={style.tableItem}>{el.email}</div>
                <div className={style.tableItem}>{el.wallet}</div>
                <div className={style.tableItem}>{el.item}</div>
                <div className={style.tableItem}>{el.recent}</div>
                <div className={style.tableItem}>{el.subscription}</div>
              </div>
            ))}
          </div>
          <img
            className={style.pageImage}
            src={process.env.PUBLIC_URL + "/image/customerpage.png"}
          />
        </div>
      </div>
    </article>
  );
};

export default CustomerManage;
