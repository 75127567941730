import { ReactComponent as Exit } from "./exit.svg";

const ExitIcon = () => {
  return (
    <>
      <Exit />
    </>
  );
};

export default ExitIcon;
