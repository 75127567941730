import Buttons from "../../../../component/buttons/Buttons";
import { ServiceProps } from "../../Service";
import style from "./style.module.scss";
import Locale from "../../../../component/locale/Locale";
import { useCurrentLocale } from "../../../../lib/function";

interface TabletServiceProps {
  onMove: () => void;
}

const TabletService: React.FC<TabletServiceProps> = ({ onMove }) => {
  const currentLocale = useCurrentLocale();
  return (
    <article className={style.tabletService}>
      <section className={style.tabletServiceContainer}>
        <figure className={style.tabletServiceImage}>
          <img
            src={"https://www.nftainment.ai/image/nftmachine.png"}
            alt="nftmachine"
          />
        </figure>
        <section className={style.tabletServiceDescription}>
          <header className={style.tabletServiceHeader1}>
            NFT Infrastructure
          </header>
          <header className={style.tabletServiceHeader2}>
            For Web3 Content
          </header>
          <div className={style.tabletServiceDescriptionWrapper}>
            <p
              className={
                currentLocale === "ko"
                  ? style.tabletServiceDescriptionTextKo
                  : style.tabletServiceDescriptionText
              }
            >
              <Locale tag="nftm_main_desc1" />
            </p>
            <p
              className={
                currentLocale === "ko"
                  ? style.tabletServiceDescriptionTextKo
                  : style.tabletServiceDescriptionText
              }
            >
              <Locale tag="nftm_main_desc2" />
            </p>
          </div>
        </section>
        <div className={style.tabletServiceButtonWrap}>
          <Buttons
            variant="org"
            style={{ width: "100%", maxWidth: "327px", height: "51px" }}
            onClick={onMove}
          >
            How It Works
          </Buttons>
        </div>
      </section>
    </article>
  );
};

export default TabletService;
