import Buttons from "../../../../component/buttons/Buttons";
import { ServiceProps } from "../../Service";
import style from "./style.module.scss";
import Locale from "../../../../component/locale/Locale";
import { useCurrentLocale } from "../../../../lib/function";

const MobileSizeService = (props: ServiceProps) => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.mobileService}>
      <article className={style.mobileContent}>
        <header className={style.mobileHeader}>
          <h1 className={style.mobileTitle}>NFT Infrastructure</h1>
          <h2 className={style.mobileSubtitle}>For Web3 Content</h2>
        </header>
        <div
          className={
            currentLocale === "ko"
              ? style.mobileDescriptionKo
              : style.mobileDescription
          }
        >
          <p>
            <Locale tag="nftm_main_desc1" />
            <Locale tag="nftm_main_desc2" />
          </p>
        </div>
      </article>
      <div className={style.mobileButtons}>
        <Buttons
          variant="org"
          style={{ width: "100%", maxWidth: "327px", height: "51px" }}
          onClick={() => props.onMove()}
        >
          How It Works
        </Buttons>
      </div>
    </section>
  );
};

export default MobileSizeService;
