import { ReactComponent as EyesClose } from "./EyesClose.svg";

const EyesCloseIcon = () => {
  return (
    <>
      <EyesClose />
    </>
  );
};

export default EyesCloseIcon;
