import ProgressBox from "../../../component/progressbox";
import Step1Icon from "../../../Icon/step/step1";
import Step2Icon from "../../../Icon/step/step2";
import Step3Icon from "../../../Icon/step/step3";
import { ProcessProps } from "../../../Type/type";
import style from "./style.module.scss";
import Locale from "../../../component/locale/Locale";
import {useCurrentLocale} from "../../../lib/function";

const MobileProcess = (props: ProcessProps) => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.mobileProcessSection}>
      <article className={style.articleDesc}>
        <div className={style.descWrap}>
          <h1>How It Works</h1>
          <h2>Our Process</h2>
          <div className={currentLocale === "ko" ? style.desc3Ko : style.desc3}>
            <p><Locale tag="nftm_main5_h3_1_mobile"/> </p>
            <p><Locale tag="nftm_main5_h3_2_mobile"/> </p>
            <p><Locale tag="nftm_main5_h3_3_mobile"/></p>
            <p><Locale tag="nftm_main5_h3_4_mobile"/></p>
          </div>
        </div>
      </article>
      <article className={style.articlePhase}>
        <div className={style.phaseWrap}>
          <Step1Icon />
          <div className={style.progressBoxGroup1}>
            <ProgressBox header="Discover" list={props.discoverList} />
            <ProgressBox header="Define" list={props.defineList} />
          </div>
        </div>
        <div className={style.phaseWrap}>
          <Step2Icon />
          <div className={style.progressBoxGroup1}>
            <ProgressBox header="Build" list={props.buildList} />
          </div>
        </div>
        <div className={style.phaseWrap}>
          <Step3Icon />
          <div className={style.progressBoxGroup1}>
            <ProgressBox header="Scale" list={props.scaleList} />
          </div>
        </div>
      </article>
    </section>
  );
};

export default MobileProcess;
