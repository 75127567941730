import style from "./style.module.scss";

import Layout from "./layout/Layout";
import { useState } from "react";
import ReloadIcon from "../../Icon/reload/ReloadIcon";
import BellsIcon from "../../Icon/bells/BellsIcon";
import CompanyProfileIcon from "../../Icon/companyProfile/CompanyProfile";
import RightArrowSilver from "../../Icon/menuIcon/RightArrowSilver";
import { DemoProps } from "../../lib/type";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../App";

const NftainmentDemo = (props: DemoProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/nftainment/login");
    return null; // 렌더링하지 않음
  }
  const header = (
    <div className={style.mainHeaderWrap}>
      <div className={style.mainHeaderContainer}>
        <div className={style.mainHeader}>{props.isHeaderNavItem}</div>
        <div className={style.mainHeaderSub}>
          {props.isHeaderSubNavItem ? (
            <div className={style.mainHeaderSubItems}>
              <RightArrowSilver />
              <div
                className={
                  props.isHeaderChildNavItem
                    ? style.mainHeader
                    : style.mainHeaderSubNavItem
                }
              >
                {props.isHeaderSubNavItem}
              </div>
              {props.isHeaderChildNavItem && (
                <>
                  <RightArrowSilver />
                  <div className={style.mainHeaderSubNavItem}>
                    {props.isHeaderChildNavItem}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={style.mainHeaderReload}>
              <ReloadIcon />
            </div>
          )}
        </div>
      </div>
      <div className={style.mainHeaderProfile}>
        <div className={style.mainHeaderProfileBellIcon}>
          <BellsIcon />
        </div>
        <div className={style.mainHeaderProfileCompanyProfileInfo}>
          <div className={style.mainHeaderProfileCompanyProfileIcon}>
            <CompanyProfileIcon />
          </div>
          <div className={style.mainHeaderProfileDetail}>
            <div className={style.mainHeaderProfileDetailName}>Admin</div>
            <div className={style.mainHeaderProfileDetailContact}>
              contact@admin.io
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <main className={style.layoutMain}>
      <Layout
        header={header}
        contentMenuHandler={props.contentMenuHandler}
        setIsContentMenuState={props.setIsContentMenuState}
      >
        {props.contentRender(props.isContentMenuState)}
      </Layout>
    </main>
  );
};

export default NftainmentDemo;
