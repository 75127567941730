import { ReactComponent as Complete } from "./complete.svg";

const CompleteIcon = () => {
  return (
    <>
      <Complete />
    </>
  );
};

export default CompleteIcon;
