import { ReactComponent as Plus } from "./Plus.svg";

const PlusIcon = () => {
  return (
    <>
      <Plus />
    </>
  );
};

export default PlusIcon;
