import { HeaderProps } from "../../../Type/type";
import Buttons from "../../buttons/Buttons";
import "./style.scss";
import TranslateIcon from "../../../Icon/translate/TranslateIcon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentLocale } from "../../../store";
import { useCurrentLocale } from "../../../lib/function";
import { useNavigate } from "react-router-dom";

const WebHeader = (props: HeaderProps) => {
  const [isMenuState, setIsMenuState] = useState(false);
  const dispatch = useDispatch();
  const currentLocale = useCurrentLocale();
  const isMenuHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsMenuState((prev) => !prev);
    event.stopPropagation();
  };

  const languageChangeToEn = () => {
    dispatch(setCurrentLocale("en"));
  };

  const languageChangeToKo = () => {
    dispatch(setCurrentLocale("ko"));
  };

  const isEnSelected =
    currentLocale === "en" ? "isSelectedItem" : "isNotSelected";

  const isKoSelected =
    currentLocale === "ko" ? "isKorSelectedItem" : "isKorNotSelectedItem";
  const navigate = useNavigate();

  useEffect(() => {
    const handleDocumentClick = () => {
      setTimeout(() => {
        setIsMenuState(false);
      }, 700);
    };
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  return (
    <header className="headerWrap">
      <div className="headerContainer">
        <div className="headerItem">
          <div className="headerLogo">
            <img
              src="https://www.nftainment.ai/image/nftainmentlogo.png"
              alt="logo"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 100,
                  behavior: "smooth",
                });
              }}
            />
          </div>
          <nav className="headerTextWrap">
            <div onClick={() => props.onMoveToService()} className="headerText">
              Service
            </div>
            <div onClick={() => props.onMoveToNaas()} className="headerText">
              Naas
            </div>
            <div onClick={() => props.onMoveToCompany()} className="headerText">
              Company
            </div>
            <div
              className="headerText"
              onClick={() => {
                navigate("/nftainment/login");
              }}
            >
              Demo
            </div>
          </nav>
        </div>
        <div className="headerLanguageChange">
          <div
            className="languageChangeWrap"
            onClick={(event) => {
              isMenuHandler(event);
            }}
          >
            <div className="languageTranslate">KOR/ENG</div>
            <TranslateIcon />
          </div>
          {isMenuState && (
            <div className={`languageMenu ${isMenuState ? "active" : ""}`}>
              <div
                className={isEnSelected}
                onClick={() => languageChangeToEn()}
              >
                ENG
              </div>
              <div
                className={isKoSelected}
                onClick={() => languageChangeToKo()}
              >
                KOR
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default WebHeader;
