import { ReactComponent as Ready } from "./ready.svg";

const ReadyIcon = () => {
  return (
    <>
      <Ready />
    </>
  );
};

export default ReadyIcon;
