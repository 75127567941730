import { AppProps } from '../../Type/type';
import {ReactComponent as Person} from './person.svg';
import {ReactComponent as MobilePerson} from './mobilePersonIcon.svg';

const PersonIcon = (props: AppProps) => {
    return <>
    {
        props.prefix && props.prefix !== "sm" ? <Person/> : <MobilePerson/>
    }
</>
}

export default PersonIcon