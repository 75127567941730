import { useState } from 'react';
import { State } from '../../store';
import { useSelector } from 'react-redux';

export const LoadLocale = (currentLocale: string) => import(`../../../libs/async/${currentLocale}.json`);

export const GetLocale = async (currentLocale: string) => {
  const data = await LoadLocale(currentLocale);
  return data as { [key: string]: string };
};
