import Buttons from "../../../../component/buttons/Buttons";
import { ServiceProps } from "../../Service";
import style from "./style.module.scss";
import Locale from "../../../../component/locale/Locale";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLocale, State } from "../../../../store";

interface WebSizeServiceProps extends ServiceProps {}

const WebSizeService: React.FC<WebSizeServiceProps> = ({ onMove }) => {
  const currentLocale = useSelector((state: State) => state.currentLocale);
  const dispatch = useDispatch();

  const changeLanguage = () => {
    if (currentLocale === "ko") {
      dispatch(setCurrentLocale("en"));
    }
    if (currentLocale === "en") {
      dispatch(setCurrentLocale("ko"));
    }
  };
  return (
    <section className={style.serviceContainer}>
      <div className={style.serviceText}>
        <header className={style.header1}>NFT Infrastructure</header>
        <header className={style.header2}>For Web3 Content</header>
        <div
          className={currentLocale === "ko" ? style.subTextKo : style.subText}
        >
          <Locale tag="nftm_main_desc1" />
          <Locale tag="nftm_main_desc2" />
        </div>
        <div className={style.serviceBtnWrap}>
          <Buttons
            variant="org"
            style={{ width: "100%", maxWidth: "156px", height: "43px" }}
            onClick={onMove}
          >
            How It Works
          </Buttons>
        </div>
      </div>
      <div className={style.serviceImage}>
        <img
          src="https://www.nftainment.ai/image/nftmachine2.png"
          alt="machine"
        />
      </div>
    </section>
  );
};

export default WebSizeService;
