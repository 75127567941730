import style from "./style.module.scss";
import ContractIcon from "../../../Icon/contractIcon/ContractIcon";
import MintingIcon from "../../../Icon/mintingIcon/MintingICon";
import PencilIcon from "../../../Icon/pencil/PencilIcon";
import CopyIcon from "../../../Icon/copyIcon/CopyIcon";
import DownArrow from "../../../Icon/downArrow/DownArrow";
import PolygonIcon from "../../../Icon/polygonIcon/PolygonIcon";
import DangerIcon from "../../../Icon/dangerIcon/DangerIcon";
import PlusIcon from "../../../Icon/plusIcon/PlusIcon";
import { ProjectManageProps } from "../../../lib/type";
import React, { useEffect, useState } from "react";
import Modal from "../../../component/modal/Modal";
import CloseIconDark from "../../../Icon/close/CloseIconDark";
import Lottie from "lottie-react";
import spinner from "../../../lottie/spinner.json";
import CompleteIcon from "../../../Icon/complete/CompleteIcon";
import ReadyIcon from "../../../Icon/ready/Ready";
import RightMark from "../../../Icon/rightMark/RightMark";
import VIcon from "../../../Icon/vIcon/VIcon";
import TreshIcon from "../../../Icon/treshIcon/TreshIcon";
import { useNavigate } from "react-router-dom";

const ProjectManage = (props: ProjectManageProps) => {
  const [isMintingStep, setIsMintingStep] = useState(0);
  const [isContractModalState, setIsContractModalState] = useState(false);
  const [isMintingModalState, setIsMintingtModalState] = useState(false);
  const [isIPFSLoading, setIsIPFSLoading] = useState(false);
  const [isContractLoading, setIsContractLoading] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [isMintingIpfsLoaing, setIsMintingIpfsLoaing] = useState(false);
  const [isMintingLoadStep, setIsMintingLoadStep] = useState(0);

  const navigate = useNavigate();
  const isMintingLoadingHandler = () => {
    setTimeout(() => {
      setIsMintingIpfsLoaing(true);
    }, 1000);
    setTimeout(() => {
      setIsMintingLoadStep(1);
    }, 1000);
    setTimeout(() => {
      setIsMintingLoadStep(2);
    }, 2000);
    setTimeout(() => {
      setIsMintingtModalState(false);
      setIsMintingStep(6);
    }, 3000);
  };

  const isContractLoadingHandler = () => {
    setTimeout(() => {
      setIsIPFSLoading(true);
    }, 1000);
    setTimeout(() => {
      setIsContractLoading(1);
    }, 1000);
    setTimeout(() => {
      setIsContractLoading(2);
    }, 2000);
    setTimeout(() => {
      // setIsContractModalState(false);
      setIsMintingStep(3);
    }, 3000);
    setTimeout(() => {
      setIsContractModalState(false);
      setIsMintingStep(4);
    }, 4000);
    setIsMintingStep(2);
  };

  const mintingModal = (
    <div className={style.mintingModalContainer}>
      <div className={style.mintingModalWrap}>
        <div className={style.mintingModalContent}>
          <div className={style.mintingModalHeader}>
            <div className={style.mintingModalTitle}>
              <div>전체 NFT 민팅</div>
              <div
                className={style.closeBtn}
                onClick={() => {
                  setIsMintingtModalState(false);
                  isMintingLoadingHandler();
                }}
              >
                <CloseIconDark />
              </div>
            </div>
            <div className={style.mintingModalDesc}>
              NFT 리스트에 존재하는 아이템을 전체 민팅합니다. 토큰ID는 지정한
              토큰 ID를 제외하고 랜덤 지정됩니다. 지정한 토큰 id는 토큰 ID
              범위를 초과해 지정할 수 없습니다.
            </div>
          </div>
          <div className={style.mintingModalDetails}>
            <div className={style.mintingModalNftList}>
              <div className={style.mintingModalNftListTitle}>NFT 리스트</div>
              <div className={style.mintingModalNftListValue}>총 500개</div>
            </div>
            <div className={style.mintingModalNftRange}>
              <div className={style.mintingModalRangeTitle}>토큰 ID 범위</div>
              <div className={style.mintingModalValue}>1~500</div>
            </div>
            <div className={style.mintingModalTokenInfo}>
              <div className={style.mintingModalTokenHeader}>
                <div className={style.dot}></div>
                <div className={style.mintingModalTokenInfoTitle}>
                  지정한 토큰 ID
                </div>
              </div>
              <div className={style.mintingModalTokenInfoDetails}>
                <div>#1, #2, #3, #4, #5, #20, </div>
                <div>#100, #200, #333</div>
              </div>
            </div>
          </div>
        </div>
        <button
          className={style.mintingBtn}
          onClick={() => {
            setIsMinting(true);
            isMintingLoadingHandler();
            setIsMintingStep(5);
          }}
        >
          민팅하기
        </button>
        {isMintingStep === 4 && (
          <div className={style.finger6}>
            <img
              src={process.env.PUBLIC_URL + "/image/leftfinger.png"}
              alt="finger"
            />
          </div>
        )}
      </div>
    </div>
  );

  const contractModal = (
    <div className={style.contractModal}>
      <div className={style.contractModalWrap}>
        <div className={style.contractModalHeader}>
          <div className={style.contractModalHeaderTitle}>
            컨트랙트 배포정보
          </div>
          <div
            className={style.exitContractModal}
            onClick={() => setIsContractModalState(false)}
          >
            <CloseIconDark />
          </div>
        </div>
        <div className={style.contractModalContentStep1}>
          <div className={style.contractModalInfoWrap}>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                컬렉션 이름
              </div>
              <div className={style.contractModalInfoDetailsValue}>
                ABCDE’s Magic World
              </div>
            </div>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                컬렉션 심볼
              </div>
              <div className={style.contractModalInfoDetailsValue}>ABCDE</div>
            </div>
          </div>
          <div className={style.contractModalInfoWrap}>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                민팅 타입
              </div>
              <div className={style.contractModalInfoDetailsValue}>
                스탠다드
              </div>
            </div>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                전체 토큰 발행량
              </div>
              <div className={style.contractModalInfoDetailsValue}>10000</div>
            </div>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                계정당 최대 구매 수량
              </div>
              <div className={style.contractModalInfoDetailsValue}>5</div>
            </div>
          </div>
          <div className={style.contractModalInfoWrap2}>
            <div className={style.contractModalInfoDetails}>
              <div className={style.contractModalInfoDetailsTitle}>
                컨트랙트 소유자
              </div>
              <div className={style.contractModalInfoDetailsValue}>
                0x983d...29d5de
              </div>
            </div>
          </div>
        </div>
        <div className={style.deployBtnWrap}>
          <div className={style.finger3}>
            <img
              src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
              alt="finger"
            />
          </div>
          <button
            className={style.deployBtn}
            onClick={() => {
              isContractLoadingHandler();
            }}
          >
            배포하기
          </button>
        </div>
      </div>
    </div>
  );

  const isContractStatusRenderer = (isContractLoading: number) => {
    switch (isContractLoading) {
      case 0:
        return <ReadyIcon />;
      case 1:
        return (
          <div className={style.lottieWrap}>
            <Lottie animationData={spinner} />
          </div>
        );
      case 2:
        return (
          <div className={style.iconWrap}>
            <CompleteIcon />
          </div>
        );

      default:
        return (
          <div className={style.iconWrap}>
            <ReadyIcon />
          </div>
        );
    }
  };

  const isMintingStatusRenderer = (isMintingLoadStep: number) => {
    switch (isMintingLoadStep) {
      case 0:
        return <ReadyIcon />;
      case 1:
        return (
          <div className={style.lottieWrap}>
            <Lottie animationData={spinner} />
          </div>
        );
      case 2:
        return (
          <div className={style.iconWrap}>
            <CompleteIcon />
          </div>
        );
    }
  };
  const isMintingLoading = (isMintingStep: number) => {
    switch (isMintingStep) {
      case 4:
        return mintingModal;
      case 5:
        return (
          <div className={style.deployContract}>
            <div className={style.deployContractWrap}>
              <div className={style.deployContractTitle}>NFT 민팅하기</div>
              <div className={style.deployStatusWrap}>
                <div className={style.deployStatus}>
                  {isMintingIpfsLoaing ? (
                    <>
                      <div className={style.iconWrap}>
                        <CompleteIcon />
                      </div>
                      <div>컬렉션 정보 IPFS 업로드</div>
                    </>
                  ) : (
                    <>
                      <div className={style.lottieWrap}>
                        <Lottie animationData={spinner} />
                      </div>
                      <div>NFT 정보 IPFS 업로드</div>
                    </>
                  )}
                </div>
                <div className={style.deployStatus}>
                  <div>{isMintingStatusRenderer(isMintingLoadStep)}</div>
                  <div>NFT 민팅</div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return;
    }
  };
  const isContractModal = (isMintingStep: number) => {
    switch (isMintingStep) {
      case 1:
        return contractModal;
      case 2:
        return (
          <div className={style.deployContract}>
            <div className={style.deployContractWrap}>
              <div className={style.deployContractTitle}>컨트랙트 배포하기</div>
              <div className={style.deployStatusWrap}>
                <div className={style.deployStatus}>
                  {isIPFSLoading ? (
                    <>
                      <div className={style.iconWrap}>
                        <CompleteIcon />
                      </div>
                      <div>컬렉션 정보 IPFS 업로드</div>
                    </>
                  ) : (
                    <>
                      <div className={style.lottieWrap}>
                        <Lottie animationData={spinner} />
                      </div>
                      <div>컬렉션 정보 IPFS 업로드</div>
                    </>
                  )}
                </div>
                <div className={style.deployStatus}>
                  <div>{isContractStatusRenderer(isContractLoading)}</div>
                  <div>컨트랙트 배포</div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={style.contractComplete}>
            <div className={style.contractCompleteWrap}>
              <div className={style.contractCompleteTitle}>
                TAGLINE 컨트랙트 배포 완료
              </div>
              <div className={style.contractCompleteInfo}>
                <div className={style.contractCompleteDesc}>
                  <div className={style.contractCompleteTx}>TxHash</div>
                  <div className={style.contractCompleteTxValue}>
                    9C765C...3C5EDC
                  </div>
                </div>
                <div className={style.contractCompleteDesc}>
                  <div className={style.contractCompleteTx}>TxFee</div>
                  <div className={style.contractCompleteTxValue}>2 MATIC</div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return contractModal;
    }
  };

  const iscontractStyleRender = (isMintingStep: number) => {
    switch (isMintingStep) {
      case 1:
        return style.contractBtn;
      case 2:
        return style.contractBtnNot;
      case 3:
        return style.contractBtnReady;
      case 4:
        return style.contractBtnReady;
      case 5:
        return style.contractBtnReady;
      case 6:
        return style.contractBtnReady;

      default:
        return style.contractBtnNot;
    }
  };

  const isBtnStyleHandler = (isMintingStep: number) => {
    switch (isMintingStep) {
      case 3:
        return style.mintingBtn;
      case 4:
        return style.mintingBtn;
      case 5:
        return style.mintingBtn;
      case 6:
        return style.mintingBtnComplete;

      default:
        return style.mintingBtnNot;
    }
  };
  const itemRenderer = () => {
    if (props.isAddedNft && isMintingStep !== 6) {
      return (
        <img src="https://www.nftainment.ai/image/addedNft.png" alt="project" />
      );
    } else if (!props.isAddedNft && isMintingStep !== 6) {
      return (
        <img
          src="https://www.nftainment.ai/image/nftItemList.png"
          alt="project"
        />
      );
    } else {
      return (
        <img
          src="https://www.nftainment.ai/image/mintedList.png"
          alt="project"
        />
      );
    }
  };
  useEffect(() => {
    if (props.isAddedNft) {
      setIsMintingStep(1);
    }
    console.log(isMintingStep);
  }, []);

  return (
    <article
      className={style.projectManageArticle}
      style={isMintingStep === 0 ? { height: "100vh" } : {}}
    >
      <div className={style.projectManageWrap}>
        <div className={style.projectManageHeader}>
          <Modal isModalOpen={isContractModalState}>
            {isContractModal(isMintingStep)}
          </Modal>
          <Modal isModalOpen={isMintingModalState}>
            {isMintingLoading(isMintingStep)}
          </Modal>
          <div className={style.projectManageTitle}>프로젝트 관리</div>
          <div className={style.projectManageBtnWrap}>
            {isMintingStep === 1 && (
              <div className={style.finger2}>
                <img
                  src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                  alt="finger"
                />
              </div>
            )}
            <button
              className={iscontractStyleRender(isMintingStep)}
              onClick={() => {
                setIsContractModalState(true);
              }}
            >
              {isMintingStep >= 3 ? (
                <VIcon />
              ) : (
                <ContractIcon
                  isContracted={isMintingStep === 4 || isMintingStep === 3}
                />
              )}
              컨트랙트 배포하기
            </button>
            <button
              className={isBtnStyleHandler(isMintingStep)}
              onClick={() => {
                setIsMintingtModalState(true);
              }}
            >
              {isMintingStep === 6 ? (
                <VIcon />
              ) : (
                <MintingIcon isReadyToMinting={isMintingStep >= 3} />
              )}
              전체 NFT민팅
            </button>
            {isMintingStep === 4 && (
              <div className={style.finger4}>
                <img
                  src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                  alt="finger"
                />
              </div>
            )}
          </div>
        </div>
        <div className={style.projectManageContent}>
          <div className={style.projectManageContentImage}>
            <img
              src="https://www.nftainment.ai/image/jisoo.png"
              alt="project"
            />
          </div>
          <div className={style.projectManageContentItems}>
            <div className={style.projectManageContentItemTitleWrap}>
              <div className={style.projectManageContentItemTitle}>
                Flower Serenade: Fan Meet Participation Pass
              </div>
              <div className={style.pencilBorder}>
                <PencilIcon />
              </div>
            </div>
            <div className={style.projectManageContentMain}>
              <div className={style.projectManageContentMainCompanyInfo}>
                <div className={style.projectManageContentMainCompanyInfoTitle}>
                  Your-Company
                </div>
                <div className={style.projectManageContentMainCompanyInfoValue}>
                  <div>0x0a...08ec</div>
                  <CopyIcon />
                </div>
              </div>
              <div className={style.projectManageContentItemDesc}>
                블랙핑크 지수의 ME 앨범 발매 기념으로 진행되는 NFT
                프로젝트입니다. 팬들과 아티스트 간의 교류와 상호작용을 촉진하고,
                그룹과 팬들 간의 특별한 연결을 형성합니다.
                <br /> 이 NFT를 소유한 팬들은 그룹과의 더욱 깊은 관계를
                형성하며, 독점적인 경험을 즐길 수 있습니다. NFT를 소유한 팬들은
                그룹의 공식 팬 미팅에 참여할 수 있는 특별한 기회를 얻게 됩니다.{" "}
                <br />이 NFT는 팬들에게 VIP 액세스 권한을 부여하여, 미팅에
                참석할 수 있는 자격과 특전을 제공합니다.
              </div>
              {isMintingStep === 6 && (
                <>
                  <button
                    className={style.moveToProject}
                    onClick={() => {
                      navigate("/demo/marketplace");
                    }}
                  >
                    프로젝트 홈페이지로 이동 <RightMark />
                  </button>
                  <div className={style.finger5}>
                    <img
                      src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                      alt="finger"
                    />
                  </div>
                </>
              )}
            </div>
            <div className={style.projectManageContentDate}>
              <div>프로젝트 일정</div>
              <DownArrow />
            </div>
            <div className={style.projectManageContentChainInfo}>
              <div className={style.chainInfoItemWrap}>
                <div className={style.chainInfoHead}>체인</div>
                <div className={style.chainInfoValue}>
                  <PolygonIcon />
                  <div>Polygon</div>
                </div>
              </div>
              <div className={style.projectManageContentChainInfo}>
                <div className={style.tokenInfo}>
                  <div className={style.tokenInfoTitle}>토큰 표준</div>
                  <div className={style.tokenInfoValue}>ERC-721</div>
                </div>
              </div>
              <div className={style.projectManageContentChainInfo}>
                <div className={style.mintingInfo}>
                  <div className={style.mintingInfoTitle}>민팅</div>
                  <div className={style.mintingInfoValue}>스탠다드</div>
                </div>
              </div>
              <div className={style.projectManageContentChainInfo}>
                <div className={style.contractInfo}>
                  <div className={style.contractInfoTitle}>컨트랙트 주소</div>
                  <div className={style.ContractInfoValue}>
                    {isMintingStep >= 3 ? (
                      <>
                        <div className={style.deployedContract}>
                          0x9232...2443
                        </div>
                        <TreshIcon />
                      </>
                    ) : (
                      <>
                        <div>미발행</div>
                        <DangerIcon />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.projectManageBottom}>
          <div className={style.projectManageBottomItem}>
            <div className={style.projectManageBottomItemTitle}>NFT리스트</div>
            <button
              className={props.isAddedNft ? style.nftBtnNot : style.nftBtn}
              onClick={() => {
                if (!props.isAddedNft) {
                  props.setIsContentMenuState &&
                    props.setIsContentMenuState("newRegistration");
                  props.contentMenuHandler &&
                    props.contentMenuHandler("newRegistration");
                }
              }}
            >
              <PlusIcon />
              NFT 신규등록
            </button>
            {!props.isAddedNft && (
              <div className={style.finger}>
                <img
                  src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                  alt="finger"
                />
              </div>
            )}
          </div>
          <div>{itemRenderer()}</div>
        </div>
      </div>
    </article>
  );
};

export default ProjectManage;
