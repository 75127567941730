import { ReactComponent as Calendar } from "./calendar.svg";

const CalendarIconIcon = () => {
  return (
    <>
      <Calendar />
    </>
  );
};

export default CalendarIconIcon;
