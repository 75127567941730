import { ReactComponent as NftainmentLogo } from "./NftainmentLogo.svg";

const NftainmentLogoBlack = () => {
  return (
    <>
      <NftainmentLogo />
    </>
  );
};

export default NftainmentLogoBlack;
