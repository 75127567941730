import { MenuIconProps } from "../../lib/type";

const ApiIcon = (props: MenuIconProps) => {
  return (
    <>
      <svg
        width="18"
        height="8"
        viewBox="0 0 18 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.874 4.99999C12.6516 5.85823 12.1504 6.61831 11.4493 7.16092C10.7481 7.70352 9.8866 7.99793 9 7.99793C8.1134 7.99793 7.2519 7.70352 6.55074 7.16092C5.84957 6.61831 5.34844 5.85823 5.126 4.99999H0V2.99999H5.126C5.34844 2.14175 5.84957 1.38167 6.55074 0.839062C7.2519 0.296456 8.1134 0.00204468 9 0.00204468C9.8866 0.00204468 10.7481 0.296456 11.4493 0.839062C12.1504 1.38167 12.6516 2.14175 12.874 2.99999H18V4.99999H12.874ZM9 5.99999C9.53043 5.99999 10.0391 5.78927 10.4142 5.4142C10.7893 5.03913 11 4.53042 11 3.99999C11 3.46956 10.7893 2.96085 10.4142 2.58578C10.0391 2.2107 9.53043 1.99999 9 1.99999C8.46957 1.99999 7.96086 2.2107 7.58579 2.58578C7.21071 2.96085 7 3.46956 7 3.99999C7 4.53042 7.21071 5.03913 7.58579 5.4142C7.96086 5.78927 8.46957 5.99999 9 5.99999Z"
          fill={props.isSelected ? "#ffffff" : "#D1D5DB"}
        />
      </svg>
    </>
  );
};

export default ApiIcon;
