import { MenuIconProps } from "../../lib/type";

const ProjectIcon = (props: MenuIconProps) => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2H2V18H16V6H12V2ZM0 0.992C0 0.444 0.447 0 0.999 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.730378 19.9982 0.479017 19.8931 0.293218 19.7075C0.107418 19.5219 0.00209465 19.2706 0 19.008V0.992ZM9 6V10H13C13 10.7911 12.7654 11.5645 12.3259 12.2223C11.8864 12.8801 11.2616 13.3928 10.5307 13.6955C9.79983 13.9983 8.99556 14.0775 8.21964 13.9231C7.44371 13.7688 6.73098 13.3878 6.17157 12.8284C5.61216 12.269 5.2312 11.5563 5.07686 10.7804C4.92252 10.0044 5.00173 9.20017 5.30448 8.46927C5.60723 7.73836 6.11992 7.11365 6.77772 6.67412C7.43552 6.2346 8.20887 6 9 6Z"
          fill={props.isSelected ? "#ffffff" : "#D1D5DB"}
        />
      </svg>
    </>
  );
};

export default ProjectIcon;
