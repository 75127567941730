import { ReactComponent as RightMark } from "./RightMark.svg";

const RightMarkIcon = () => {
  return (
    <>
      <RightMark />
    </>
  );
};

export default RightMarkIcon;
