import { ReactNode } from "react";
import React from "react";
import { classnames } from "../../lib/class";

import "./style.scss";

interface ButtonProps {
    roundless?: boolean;
    borderless?: boolean;
    children: ReactNode;
    variant?: string;

    class?: string;
    style?: React.CSSProperties | undefined

    onClick?: (e: any) => void;
}

const Buttons = (props: ButtonProps) => {
    return <button
        className={classnames(
            "Button",
            props.borderless && "Borderless",
            props.roundless && "Roundless",
            props.variant && `Btn-${props.variant}`
        )}
        style={props.style}
        onClick={props.onClick}
    >
        {props.children}
    </button>;
}

export default Buttons