import { ReactComponent as Copy } from "./Copy.svg";

const CopyIcon = () => {
  return (
    <>
      <Copy />
    </>
  );
};

export default CopyIcon;
