
import CircleCheck from "../../Icon/circleCheck/CircleCheck"
import style from "./style.module.scss"
import {useCurrentLocale} from "../../lib/function";

interface CheckListProps {
    circle: string;
    desc: JSX.Element[];
}

const CheckList = (props: CheckListProps) => {
    const currentLocale = useCurrentLocale();
    return <div className={style.workTextCheckList}>
        <div className={style.checkListWrap}>
            <div className={style.orangeCircle}>
                {props.circle}
            </div>
            <div className={style.listWrap}>
                {
                    props.desc.map((el, index) => (
                        <div className={currentLocale === "ko" ? style.checkListContainerKo : style.checkListContainer} key={index}>
                            <div className={style.iconWrap}>
                                <CircleCheck />
                            </div>
                            <div>
                                {el}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
}

export default CheckList