import { AppProps } from "../../Type/type";
import ProgressBox from "../../component/progressbox";
import Step1Icon from "../../Icon/step/step1";
import Step2Icon from "../../Icon/step/step2";
import Step3Icon from "../../Icon/step/step3";
import style from "./style.module.scss";
import { forwardRef } from "react";
import WebProcess from "./webProcess/WebProcess";
import MobileProcess from "./mobileProcess/MobileProcess";
import Locale from "../../component/locale/Locale";

const discoverList = [
  <Locale tag="nftm_main5_card_list1"/>,
  <Locale tag="nftm_main5_card_list2"/>,
  <Locale tag="nftm_main5_card_list3"/>,
];
const defineList = [
  <Locale tag="nftm_main5_card_list4"/>,
  <Locale tag="nftm_main5_card_list5"/>,
  <Locale tag="nftm_main5_card_list6"/>,
];
const buildList = [
  <Locale tag="nftm_main5_card_list7"/>,
  <Locale tag="nftm_main5_card_list8"/>,
  <Locale tag="nftm_main5_card_list9"/>,
  <Locale tag="nftm_main5_card_list10"/>,
  <Locale tag="nftm_main5_card_list11"/>,
  <Locale tag="nftm_main5_card_list12"/>,
];
const scaleList = [
  <Locale tag="nftm_main5_card_list13"/>,
  <Locale tag="nftm_main5_card_list14"/>,
  <Locale tag="nftm_main5_card_list15"/>,
];

const isMobileRenderer = (prefix: string) => {
  switch (prefix) {
    case "xxl":
      return (
        <WebProcess
          discoverList={discoverList}
          defineList={defineList}
          buildList={buildList}
          scaleList={scaleList}
        />
      );
    case "xl":
      return (
        <WebProcess
          discoverList={discoverList}
          defineList={defineList}
          buildList={buildList}
          scaleList={scaleList}
        />
      );
    case "md":
      return (
        <MobileProcess
          discoverList={discoverList}
          defineList={defineList}
          buildList={buildList}
          scaleList={scaleList}
        />
      );
    case "sm":
      return (
        <MobileProcess
          discoverList={discoverList}
          defineList={defineList}
          buildList={buildList}
          scaleList={scaleList}
        />
      );
    default:
      return (
        <WebProcess
          discoverList={discoverList}
          defineList={defineList}
          buildList={buildList}
          scaleList={scaleList}
        />
      );
  }
};

const Process = forwardRef<HTMLDivElement, AppProps>((props, ref) => (
  <section ref={ref}>
    {props.prefix && isMobileRenderer(props.prefix)}
  </section>
));
export default Process;
