import SideMenu from "../../component/sideMenu/SideMenu";
import Header from "../../component/header/Header";
import Service from "../service/Service";
import CompanyInfo from "../company/CompanyInfo";
import Works from "../works/Works";
import Process from "../process/Process";
import CompanyLeadership from "../companyInc/CompanyLeadership";
import Footer from "../../component/footer/Footer";
import React from "react";


interface LandingPageProps {
    onMoveToCompany: () => void;
    onMoveToNaas: () => void;
    onMoveToService: () => void;
    onCloseSideMenu?: () => void;
    onModalOpener? : () => void;
    sideMenuState?: string;
    prefix?: string;
    onMoveToHowItWorks: () => void
    serviceRef: React.RefObject<HTMLDivElement>
    naasRef: React.RefObject<HTMLDivElement>
    howItWorksRef: React.RefObject<HTMLDivElement>
    companyRef: React.RefObject<HTMLDivElement>
}

const LandingPage = (props: LandingPageProps) => {
    return <>
        <SideMenu
            sideMenuState={props.sideMenuState}
            onCloseSideMenu={props.onCloseSideMenu}
            onMoveToCompany={props.onMoveToCompany}
            onMoveToNaas={props.onMoveToNaas}
            onMoveToService={props.onMoveToService}
            onModalOpener={props.onModalOpener}
            prefix={props.prefix}
        />
        <Header
            prefix={props.prefix}
            onMoveToCompany={props.onMoveToCompany}
            onMoveToService={props.onMoveToService}
            onMoveToNaas={props.onMoveToNaas}
            onModalOpener={props.onModalOpener}
        />
        <Service prefix={props.prefix} onMove={props.onMoveToHowItWorks} />
        <CompanyInfo prefix={props.prefix} ref={props.serviceRef} />
        <Works prefix={props.prefix} ref={props.naasRef} />
        <Process prefix={props.prefix} ref={props.howItWorksRef} />
        <CompanyLeadership prefix={props.prefix} ref={props.companyRef} />
        <Footer prefix={props.prefix} />
    </>
}

export default LandingPage