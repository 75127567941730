interface ContractIconProps {
  isContracted: boolean;
}
const ContractIcon = (props: ContractIconProps) => {
  return (
    <>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.20545 4.5C3.35579 4.5 2.66699 5.09696 2.66699 5.83333C2.66699 6.56971 3.35579 7.16667 4.20545 7.16667H21.1285C21.9782 7.16667 22.667 6.56971 22.667 5.83333C22.667 5.09696 21.9782 4.5 21.1285 4.5H4.20545Z"
          fill={props.isContracted ? "#2463EB" : "white"}
        />
        <path
          d="M4.38128 11.1667C3.43451 11.1667 2.66699 11.7636 2.66699 12.5C2.66699 13.2364 3.43451 13.8333 4.38128 13.8333H14.286C15.2328 13.8333 16.0003 13.2364 16.0003 12.5C16.0003 11.7636 15.2328 11.1667 14.286 11.1667H4.38128Z"
          fill={props.isContracted ? "#2463EB" : "white"}
        />
        <path
          d="M4.44477 17.8333C3.46294 17.8333 2.66699 18.4303 2.66699 19.1667C2.66699 19.9031 3.46294 20.5 4.44477 20.5H10.2225C11.2044 20.5 12.0003 19.9031 12.0003 19.1667C12.0003 18.4303 11.2044 17.8333 10.2225 17.8333H4.44477Z"
          fill={props.isContracted ? "#2463EB" : "white"}
        />
        <path
          d="M22.667 28.5C22.667 29.2364 23.2639 29.8333 24.0003 29.8333C24.7367 29.8333 25.3337 29.2364 25.3337 28.5V15.7189L28.3909 18.7761C28.9115 19.2968 29.7558 19.2968 30.2765 18.7761C30.7971 18.2555 30.7971 17.4112 30.2765 16.8905L24.9431 11.5572C24.6931 11.3071 24.3539 11.1667 24.0003 11.1667C23.6467 11.1667 23.3075 11.3071 23.0575 11.5572L17.7242 16.8905C17.2035 17.4112 17.2035 18.2555 17.7242 18.7761C18.2449 19.2968 19.0891 19.2968 19.6098 18.7761L22.667 15.7189V28.5Z"
          fill={props.isContracted ? "#2463EB" : "white"}
        />
      </svg>
    </>
  );
};

export default ContractIcon;
