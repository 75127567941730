import DefaultWalletIcon from "../../../Icon/defaultWallet/DefaultWallet";
import Filter from "../../../Icon/filterIcon/FliterIcon";
import style from "./style.module.scss";
import { useState } from "react";
import { ProjectProps } from "../../../lib/type";

const CustomerDetail = (props: ProjectProps) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <article className={style.detailArticle}>
      {isClicked && (
        <div className={style.finger2}>
          <img src={process.env.PUBLIC_URL + "/image/leftfinger.png"} />
        </div>
      )}
      {isClicked && (
        <div
          className={style.menuOverWrap}
          onClick={() => {
            props.setIsContentMenuState &&
              props.setIsContentMenuState("revenue");
            props.contentMenuHandler && props.contentMenuHandler("revenue");
          }}
        ></div>
      )}
      <div className={style.detailWrap}>
        <div className={style.detailHeader}>
          <div className={style.detailProfileImage}>
            <img
              src={process.env.PUBLIC_URL + "/image/demoprofile.png"}
              alt="detail"
            />
          </div>
          <div className={style.detailProfileInfo}>
            <div className={style.detailProfileEmail}>
              susanna.Lind57@gmail.com
            </div>
            <div className={style.detailInfoDesc}>
              <DefaultWalletIcon />
              0xC2....6205
            </div>
          </div>
        </div>

        <div className={style.detailContent}>
          {!isClicked && (
            <>
              <div className={style.finger}>
                <img src={process.env.PUBLIC_URL + "/image/rightfinger.png"} />
              </div>
              <div className={style.detailContentHeader}>
                <div className={style.detailItemWrap}>
                  <div className={style.itemInfo}>보유 아이템</div>
                  <div className={style.itemNumber}>20</div>
                </div>

                <div
                  className={style.dealHistory}
                  onClick={() => {
                    setIsClicked(true);
                  }}
                >
                  거래내역
                </div>
                <div className={style.favProjectWrap}>
                  <div className={style.favProjectItem}>관심프로젝트</div>
                  <div className={style.favProjectNumber}>4</div>
                </div>
                <div className={style.favItemWrap}>
                  <div className={style.favItemItem}>관심 아이템</div>
                  <div className={style.favItemNumber}>10</div>
                </div>
              </div>
            </>
          )}

          <div className={style.detailFilter}>
            <div className={style.detailFilterTitle}>Polygon</div>
            <div className={style.filterBtn}>
              <Filter />
              필터
            </div>
          </div>
          <div>
            {isClicked ? (
              <img
                src={process.env.PUBLIC_URL + "/image/dealhistory.png"}
                alt="detail"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/image/customerItems.png"}
                alt="detail"
              />
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default CustomerDetail;
