import { ReactComponent as Tresh } from "./Tresh.svg";

const TreshIcon = () => {
  return (
    <div>
      <Tresh />
    </div>
  );
};

export default TreshIcon;
