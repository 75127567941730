import { ReactComponent as Deal } from "./Deal.svg";

const DealIcon = () => {
  return (
    <>
      <Deal />
    </>
  );
};

export default DealIcon;
