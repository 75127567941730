import LineSvg from "../../../Icon/line/Line";
import FacebookIcon from "../../../Icon/snsIcon/facebook/FacebookIcon";
import InstagramIcon from "../../../Icon/snsIcon/instagram/InstagramIcon";
import TwitterIcon from "../../../Icon/snsIcon/twitter/TwitterIcon";
import YoutubeIcon from "../../../Icon/snsIcon/youtube/YoutubeIcon";
import style from "./style.module.scss";

const MobileFooter = () => {
  const iconArray = [
    <YoutubeIcon />,
    <InstagramIcon />,
    <TwitterIcon />,
    <FacebookIcon />,
  ];
  return (
    <footer>
      <section className={style.footerSection}>
        <article className={style.titleArticle}>
          <div className={style.footerLogo}>
            <img src="https://www.nftainment.ai/image/footerlogo.png" />
            <div className={style.iconContainer}>
              {iconArray.map((el, index) => (
                <div className={style.iconCircle} key={index}>
                  {el}
                </div>
              ))}
            </div>
          </div>
          <p className={style.footerTitle}>The Web3 Culture Company</p>
        </article>
        <article className={style.footerInfo}>
          <div className={style.companyInfo}>
            <div className={style.infoList}>
              <h4>Quick Links</h4>
              <div>bitBlue</div>
              <div>Announcement</div>
              <div>Privacy Podivcy</div>
              <div>FAQ</div>
            </div>
            <div className={style.infoList}>
              <h4>Others</h4>

              <div>NFT-Service</div>
              <div>How to Video's</div>
              <div>Get Listed</div>
              <div>Affidivate</div>
            </div>
            <div className={style.infoList}>
              <h4>About Us</h4>
              <div>Terms & Conditions</div>
              <div>Disclaimer</div>
              <div>Imprint</div>
              <div>Contact US</div>
            </div>
          </div>
        </article>
        <article>
          <LineSvg />
          <div className={style.bitblueInfo}>
            <div>contact@bitblue.team</div>
            <div>NFTainment and NFT Solutions</div>
            <div>Copyrights 2023 bitBLUE Inc. All Rights Reserved.</div>
          </div>
        </article>
      </section>
    </footer>
  );
};

export default MobileFooter;
