import style from "./style.module.scss";
import PolygonIcon from "../../../Icon/polygonIcon/PolygonIcon";
import Modal from "../../../component/modal/Modal";
import DownArrow from "../../../Icon/downArrow/DownArrow";
import TreshIcon from "../../../Icon/treshIcon/TreshIcon";
import { NewProjectProps } from "../../../lib/type";
import { useState } from "react";

const NewRegistration = (props: NewProjectProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const Modalcomponent = (
    <div className={style.newModal}>
      <div className={style.newModalContainer}>
        <div className={style.newHeaderWrap}>
          <div className={style.newHeaderTitle}>NFT 속성 추가하기</div>
          <div className={style.newHeaderDesc}>
            속성은 항목 아래에 표시되며 클릭할 수 있고 컬렉션에서
            <br /> 필터링할 수 있습니다.
          </div>
        </div>
        <div className={style.newModalContent}>
          <div className={style.newModalTapWrap}>
            <div className={style.newModalTapTitle1}>직접 입력</div>
            <div className={style.newModalTapTitle2}>일괄 입력</div>
          </div>
          <div className={style.newModalContentItem}>
            <div className={style.newModalContentItemHeader}>
              <div className={style.newModalContentItemHeaderItem}>타입</div>
              <div className={style.newModalContentItemHeaderItem}>이름</div>
            </div>
            <div className={style.newModalInputContainer}>
              <div className={style.newModalInputWrap}>
                <div className={style.newModalInput}>
                  <div className={style.newModalInputItem}>
                    모양
                    <DownArrow />
                  </div>
                </div>
                <div className={style.newModalInput}>
                  <div className={style.newModalInputItem}>
                    하트
                    <DownArrow />
                  </div>
                </div>
                <TreshIcon />
              </div>
              <div className={style.newModalInputWrap}>
                <div className={style.newModalInput}>
                  <div className={style.newModalInputItem}>
                    색
                    <DownArrow />
                  </div>
                </div>
                <div className={style.newModalInput}>
                  <div className={style.newModalInputItem}>
                    보라
                    <DownArrow />
                  </div>
                </div>
                <TreshIcon />
              </div>
            </div>
          </div>
          <div className={style.newModalAddBtnWrap}>
            <div className={style.finger2}>
              <img src={process.env.PUBLIC_URL + "/image/leftfinger.png"} />
            </div>
            <button className={style.newModalAddBtn}>추가하기</button>
          </div>
        </div>
        <div
          className={style.saveBtnWrap}
          onClick={() => {
            props.setIsContentMenuState &&
              props.setIsContentMenuState("projectManage");
            props.contentMenuHandler &&
              props.contentMenuHandler("projectManage");
            props.setAddedNft && props.setAddedNft(true);
          }}
        >
          <button className={style.saveBtn}>저장하기</button>
        </div>
      </div>
    </div>
  );
  return (
    <article className={style.newRegistArticle}>
      <Modal isModalOpen={isModalOpen}>{Modalcomponent}</Modal>
      <div className={style.newRegistWrap}>
        <div className={style.newRegistTitle}>NFT 신규등록</div>
        <div className={style.newRegistContentWrap}>
          <div className={style.newRegistContentInput}>
            <div className={style.newRegistImg}>
              <img
                src="https://www.nftainment.ai/image/newRegist.png"
                alt="project"
              />
            </div>
            <div className={style.newRegistInputWrap}>
              <button className={style.newRegistInputBtnCancel}>취소</button>
              <button
                className={style.newRegistInputBtnSave}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                등록
              </button>
              <div className={style.finger}>
                <img src={process.env.PUBLIC_URL + "/image/leftfinger.png"} />
              </div>
            </div>
          </div>
          <div className={style.newRegistContentInfo}>
            <div className={style.newRegistContentInfoImageWrap}>
              <div className={style.newRegistContentInfoImageDisplay}>
                미리보기
              </div>
              <img
                src="https://www.nftainment.ai/image/jisoo2.jpeg"
                alt="project"
              />
            </div>
            <div className={style.newRegistContentInfoDesc}>
              <div className={style.newRegistContentInfoDescContainer}>
                <div className={style.newRegistContentInfoDescWrap}>
                  <div className={style.newRegistContentInfoDescTitleWrap}>
                    <div className={style.newRegistContentInfoDescTitle}>
                      Open Edition
                    </div>
                    <PolygonIcon />
                  </div>
                  <div className={style.newRegistContentInfoDescContent}>
                    <div className={style.newRegistContentInfoDescContentTitle}>
                      <div
                        className={
                          style.newRegistContentInfoDescContentTitleItem
                        }
                      >
                        Flower Serenade: Fan Meet
                      </div>
                      <div
                        className={
                          style.newRegistContentInfoDescContentTitleItem
                        }
                      >
                        Participation Pass #1
                      </div>
                    </div>
                    <div
                      className={style.newRegistContentInfoDescContentTitleItem}
                    >
                      100 MATIC
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.newRegistContentInfoDetails}>
                <div className={style.newRegistContentInfoDetailsItemWrap}>
                  <div className={style.newRegistContentInfoDetailsItem}>
                    <div>체인</div>
                    <div>Polygon</div>
                  </div>
                  <div className={style.newRegistContentInfoDetailsItem}>
                    <div>토큰 표준</div>
                    <div>ERC-721</div>
                  </div>
                  <div className={style.newRegistContentInfoDetailsItem}>
                    <div>컨트랙트 주소</div>
                    <div>0xf491...448c</div>
                  </div>
                  <div className={style.newRegistContentInfoDetailsItem}>
                    <div>토큰 ID</div>
                    <div>1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default NewRegistration;
