import style from "./style.module.scss";
import { LayoutProps } from "../../../lib/type";
import ProjectIcon from "../../../Icon/menuIcon/ProjectIcon";
import { useEffect, useRef, useState } from "react";
import RightArrow from "../../../Icon/menuIcon/RightArrow";
import RevenueIcon from "../../../Icon/menuIcon/RevenueIcon";
import CustomerIcon from "../../../Icon/menuIcon/CustomerIcon";
import WalletIcon from "../../../Icon/menuIcon/WalletIcon";
import FileIcon from "../../../Icon/menuIcon/FileIcon";
import ApiIcon from "../../../Icon/menuIcon/ApiIcon";
import ManagerSettingsIcon from "../../../Icon/menuIcon/ManagerSettingsIcon";
import CustomerServiceIcon from "../../../Icon/menuIcon/CustomerServiceIcon";
import Nftainmentlogo from "../../../Icon/nftainmentlogo/Nftainmentlogo";

const Layout = (props: LayoutProps) => {
  const [menuState, setMenuState] = useState("");
  const sideMenuItems = [
    {
      icon: <ProjectIcon isSelected={"project" === menuState} />,
      title: "프로젝트 조회",
      right: <RightArrow isSelected={"project" === menuState} />,
      id: "project",
    },
    {
      icon: <RevenueIcon isSelected={"revenue" === menuState} />,
      title: "수익조회",
      right: <RightArrow isSelected={"revenue" === menuState} />,
      id: "revenue",
    },
    {
      icon: <CustomerIcon isSelected={"customer" === menuState} />,
      title: "회원관리",
      right: <RightArrow isSelected={"customer" === menuState} />,
      id: "customer",
    },
    {
      icon: <WalletIcon isSelected={"wallet" === menuState} />,
      title: "지갑관리",
      right: <RightArrow isSelected={"wallet" === menuState} />,
      id: "wallet",
    },
    {
      icon: <FileIcon isSelected={"file" === menuState} />,
      title: "문서관리",
      right: <RightArrow isSelected={"file" === menuState} />,
      id: "file",
    },
    {
      icon: <ApiIcon isSelected={"api" === menuState} />,
      title: "API관리",
      right: <RightArrow isSelected={"api" === menuState} />,
      id: "api",
    },
    {
      icon: <ManagerSettingsIcon isSelected={"manager" === menuState} />,
      title: "관리자설정",
      right: <RightArrow isSelected={"manager" === menuState} />,
      id: "manager",
    },
    {
      icon: <CustomerServiceIcon isSelected={"service" === menuState} />,
      title: "고객서비스",
      right: <RightArrow isSelected={"service" === menuState} />,
      id: "service",
    },
  ];

  return (
    <main className={style.layoutMain}>
      <nav className={style.layoutSideNav}>
        <div className={style.layoutSideNavLogo}>
          <Nftainmentlogo />
        </div>
        <div className={style.layoutSideNavWrap}>
          {sideMenuItems.map((el) => (
            <div
              className={style.layoutSideNavItemWrap}
              onClick={() => {
                props.setIsContentMenuState &&
                  props.setIsContentMenuState(el.id);
                props.contentMenuHandler && props.contentMenuHandler(el.id);
                setMenuState(el.id);
              }}
            >
              <div className={style.layoutSideNavItems}>
                <div className={style.layoutSideNavItemIcon}>{el.icon}</div>
                <div
                  className={
                    el.id === menuState
                      ? style.layoutSideNavTitleActive
                      : style.layoutSideNavTitle
                  }
                >
                  {el.title}
                </div>
              </div>
              <div className={style.layoutSideNavRight}>{el.right}</div>
            </div>
          ))}
        </div>
      </nav>
      <section className={style.layoutContent}>
        <header className={style.layoutHeader}>{props.header}</header>
        <article className={style.layoutContentWrap}>{props.children}</article>
      </section>
    </main>
  );
};

export default Layout;
