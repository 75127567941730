import { ReactComponent as SalesPrice } from "./SalesPrice.svg";

const SalesPriceIcon = () => {
  return (
    <>
      <SalesPrice />
    </>
  );
};

export default SalesPriceIcon;
