import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import {
  Breakpoints,
  BreakpointsPrefix,
  BreakpointsSize,
} from "./component/breakpoints/Breakpoints";
import { setCurrentLocale } from "./store";
import { useCurrentLocale } from "./lib/function";
import { useDispatch } from "react-redux";
import LandingPage from "./page/landing/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NftainmentDemo from "./page/nftainmentDemo/NftainmentDemoLanding";
import NftainmentLogin from "./page/nftainmentDemo/nftainmentLogin/NftainmentLogin";
import CustomerMarketPlace from "./page/nftainmentDemo/cutomerMarketPlace/CustomerMarketPlace";
import Dashboard from "./page/nftainmentDemo/nftainmentDemoDashboard/Dashboard";
import Project from "./page/nftainmentDemo/nftainmentProject/Project";
import Registration from "./page/nftainmentDemo/nftainmentProjectRegistration/Registration";
import ProjectManage from "./page/nftainmentDemo/projectManage/ProjectManage";
import NewRegistration from "./page/nftainmentDemo/newRegistration/NewRegistration";
import ProjectDetail from "./page/nftainmentDemo/projectDetail/ProjectDetail";
import CustomerManage from "./page/nftainmentDemo/customerManage/CustomerManage";
import CustomerDetail from "./page/nftainmentDemo/customerDetail/CustomerDetail";
import Revenue from "./page/nftainmentDemo/revenue/Revenue";
import { State as ReduxState } from "./store";

export type RootState = ReduxState;

function App() {
  const currentLocale = useCurrentLocale();
  const [isHeaderNavItem, setHeaderNavItem] = useState("대시보드");
  const [isHeaderSubNavItem, setHeaderSubNavItem] = useState<string | null>(
    null
  );
  const [isHeaderChildNavItem, setHeaderChildNavItem] = useState<string | null>(
    null
  );
  const [isContentMenuState, setIsContentMenuState] = useState("dashboard");
  const dispatch = useDispatch();

  const [prefix, setPrefix] = useState<BreakpointsPrefix | undefined>();
  const [sideMenuState, setSideMenuState] = useState("notReady");
  const [breakpointUnsubscribe, setBreakpointUnsubscribe] =
    useState<VoidFunction | null>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const naasRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const [isAddedNft, setAddedNft] = useState(false);

  const onMoveToCompnay = () => {
    if (companyRef.current) {
      companyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMoveToNaas = () => {
    if (naasRef.current) {
      naasRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMoveToService = () => {
    if (serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onMoveToHowItWorks = () => {
    if (howItWorksRef.current) {
      howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onCloseSideMenu = () => {
    setSideMenuState("close");
  };

  const onModalOpener = () => {
    if (sideMenuState === "close" || "notReady") setSideMenuState("open");
    if (sideMenuState === "open") {
      setSideMenuState("close");
    }
  };
  useEffect(() => {
    if (sideMenuState === "open") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [sideMenuState]);

  useEffect(() => {
    const unsubscribe = Breakpoints.Subscribe(
      (prefix: string, size: number) => {
        if (size > BreakpointsSize.xl) {
          setPrefix("xxl");
        } else if (size > BreakpointsSize.md) {
          setPrefix("xl");
        } else if (size > BreakpointsSize.sm) {
          setPrefix("md");
        } else if (size < BreakpointsSize.md) {
          setPrefix("sm");
        }
      },
      true
    );

    setBreakpointUnsubscribe(() => unsubscribe);

    return () => {
      if (breakpointUnsubscribe) {
        breakpointUnsubscribe();
      }
    };
  }, []);

  const contentMenuHandler = (el: string) => {
    switch (el) {
      case "dashboard":
        setHeaderNavItem("대시보드");
        setHeaderSubNavItem(null);
        break;
      case "project":
        setHeaderNavItem("프로젝트조회");
        setHeaderSubNavItem(null);

        break;
      case "revenue":
        setHeaderNavItem("수익조회");
        setHeaderSubNavItem(null);
        break;
      case "customer":
        setHeaderNavItem("회원관리");
        setHeaderSubNavItem("회원조회");
        break;
      case "projectRegistration":
        setHeaderNavItem("프로젝트 조회");
        setHeaderSubNavItem("신규 프로젝트 등록");
        break;
      case "projectManage":
        setHeaderNavItem("프로젝트 조회");
        setHeaderSubNavItem("프로젝트 관리");
        break;
      case "newRegistration":
        setHeaderNavItem("프로젝트 조회");
        setHeaderSubNavItem("NFT 신규등록");
        break;
      case "projectDetail":
        setHeaderNavItem("프로젝트 조회");
        setHeaderSubNavItem("프로젝트 상세");
        break;
      case "customerdetail":
        setHeaderNavItem("회원관리");
        setHeaderSubNavItem("회원 조회");
        setHeaderChildNavItem("회원 상세");
        break;
      case "revenue":
        setHeaderNavItem("수익조회");
        setHeaderSubNavItem(null);
        break;
      default:
        setHeaderNavItem("대시보드");
        setHeaderSubNavItem(null);
    }
  };

  const contentRender = (el: string) => {
    switch (el) {
      case "dashboard":
        return (
          <Dashboard
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "project":
        return (
          <Project
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "projectRegistration":
        return (
          <Registration
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );

      case "projectManage":
        return (
          <ProjectManage
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
            isAddedNft={isAddedNft}
          />
        );
      case "newRegistration":
        return (
          <NewRegistration
            setAddedNft={setAddedNft}
            isAddedNft={isAddedNft}
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "projectDetail":
        return (
          <ProjectDetail
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "customer":
        return (
          <CustomerManage
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "customerdetail":
        return (
          <CustomerDetail
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
      case "revenue":
        return (
          <Revenue
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );

      default:
        return (
          <Dashboard
            setIsContentMenuState={setIsContentMenuState}
            contentMenuHandler={contentMenuHandler}
          />
        );
    }
  };

  useEffect(() => {
    dispatch(setCurrentLocale("en"));
  }, []);

  return (
    <main className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LandingPage
                sideMenuState={sideMenuState}
                serviceRef={serviceRef}
                naasRef={naasRef}
                companyRef={companyRef}
                howItWorksRef={howItWorksRef}
                prefix={prefix}
                onMoveToCompany={onMoveToCompnay}
                onMoveToHowItWorks={onMoveToHowItWorks}
                onCloseSideMenu={onCloseSideMenu}
                onModalOpener={onModalOpener}
                onMoveToService={onMoveToService}
                onMoveToNaas={onMoveToNaas}
              />
            }
          />
          <Route path="/nftainment/login" element={<NftainmentLogin />} />
          <Route
            path="/demo"
            element={
              <NftainmentDemo
                setIsContentMenuState={setIsContentMenuState}
                setHeaderSubNavItem={setHeaderSubNavItem}
                setHeaderNavItem={setHeaderNavItem}
                isContentMenuState={isContentMenuState}
                isHeaderNavItem={isHeaderNavItem}
                isHeaderSubNavItem={isHeaderSubNavItem}
                contentMenuHandler={contentMenuHandler}
                contentRender={contentRender}
                isHeaderChildNavItem={isHeaderChildNavItem}
                setHeaderChildNavItem={setHeaderChildNavItem}
              />
            }
          />
          <Route
            path="/demo/marketplace"
            element={
              <CustomerMarketPlace
                setHeaderSubNavItem={setHeaderSubNavItem}
                setHeaderNavItem={setHeaderNavItem}
                setIsContentMenuState={setIsContentMenuState}
                isContentMenuState={isContentMenuState}
                isHeaderNavItem={isHeaderNavItem}
                isHeaderSubNavItem={isHeaderSubNavItem}
                contentMenuHandler={contentMenuHandler}
                contentRender={contentRender}
                isHeaderChildNavItem={isHeaderChildNavItem}
                setHeaderChildNavItem={setHeaderChildNavItem}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
