import Profile from "../../../../../component/profile/Profile";
import style from "./style.module.scss";
import Locale from "../../../../../component/locale/Locale";

const MobileLeadership = () => {
  return (
    <section className={style.mobileLeadershipWrap}>
      <div className={style.mobileTextWrap}>
        <h2 className={style.mobileTextHeader}>Leadership</h2>
        <p className={style.mobileTextDesc}>
          <Locale tag="nftm_main_leadership_desc" />
        </p>
      </div>
      <div className={style.mobileLeaderInfoWrap}>
        <Profile
          name="Eugene Joo"
          sns1="Linkedin"
          sns2="Twitter"
          email="beatblue.eth"
          url="./image/mobileceo.png"
          position="CEO & Founder"
          sns1Url="https://www.linkedin.com/in/beatblue/"
          sns2Url=" https://twitter.com/parangsori"
        />
        <Profile
          name="Chris Yoon"
          sns1="Linkedin"
          sns2="Twitter"
          email="juntak.eth"
          url="./image/mobilecso.png"
          position="CSO & CO-founder"
          sns1Url="https://www.linkedin.com/in/chris-juntak-yoon-2553934a/"
          sns2Url=" https://twitter.com/juntakyoon"
        />
      </div>
    </section>
  );
};

export default MobileLeadership;
