import Profile from "../../../../../component/profile/Profile";
import style from "./style.module.scss";
import Locale from "../../../../../component/locale/Locale";
import { useCurrentLocale } from "../../../../../lib/function";

const WebLeadership = () => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.leadershipWrap}>
      <div className={style.textWrap}>
        <h2 className={style.textHeader}>Leadership</h2>
        <p
          className={currentLocale === "ko" ? style.textDescKo : style.textDesc}
        >
          <Locale tag="nftm_main_leadership_desc" />
        </p>
      </div>
      <div className={style.leaderInfoWrap}>
        <Profile
          name="Eugene Joo"
          sns1="Linkedin"
          sns2="Twitter"
          email="beatblue.eth"
          url="./image/ceo.png"
          position="CEO & Founder"
          sns1Url="https://www.linkedin.com/in/beatblue/"
          sns2Url=" https://twitter.com/parangsori"
        />
        <Profile
          name="Chris Yoon"
          sns1="Linkedin"
          sns2="Twitter"
          email="juntak.eth"
          url="./image/cso.png"
          position="CSO & CO-founder"
          sns1Url="https://www.linkedin.com/in/chris-juntak-yoon-2553934a/"
          sns2Url=" https://twitter.com/juntakyoon"
        />
      </div>
    </section>
  );
};

export default WebLeadership;
