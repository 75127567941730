import React from "react";
import Profile from "../../../../component/profile/Profile";
import { AppProps } from "../../../../Type/type";
import MobileLeadership from "./MobileLeadership/MobileLeadership";

import style from "./style.module.scss";
import WebLeadership from "./WebLeadership/WebLeadership";

const Leadership = (props: AppProps) => {
  const isPrefixRenderer = () => {
    if (props.prefix) {
      switch (props.prefix) {
        case "xxl":
          return <WebLeadership />;
        case "xl":
          return <WebLeadership />;
        case "md":
          return <MobileLeadership />;
        case "sm":
          return <MobileLeadership />;

        default:
          return <WebLeadership />;
      }
    }
  };
  return (
   <div>
    {
      props.prefix && isPrefixRenderer()
    }
   </div>
  );
};

export default Leadership;
