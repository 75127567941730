import KnowYourSelfLogo from "../../../../../Icon/knowlogo/KnowYourSelfLogo";
import NftainmentLogo from "../../../../../Icon/nftainmentlogo/Nftainmentlogo";
import style from "./style.module.scss";
import Locale from "../../../../../component/locale/Locale";
import { useCurrentLocale } from "../../../../../lib/function";

const MobileInc = () => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.mobileIncSection}>
      <article className={style.textArticle}>
        <h1>company</h1>
        <h2>bitBLUE INC.</h2>
        <div>
          <div className={style.incDescSmalltextWrap}>
            <div className={style.incDescSmalltextItem1}>
              <p>
                <span className={style.bitblue}>bitBlue</span>{" "}
                <Locale tag="nftm_main6_desc1" />
              </p>
            </div>

            <p className={style.incDescSmalltextItem2}>
              {currentLocale === "en" && <Locale tag="nftm_main6_desc2" />}
            </p>
            <p className={style.incDescSmalltextItem2}>adoption of NFTs</p>
          </div>
        </div>
      </article>
      <article className={style.incBox}>
        <div className={style.incBoxContainer}>
          <div className={style.incContent}>
            <div className={style.incSvgWrap}>
              <div className={style.incContentItemLine}></div>
              <NftainmentLogo />
            </div>
            <div className={style.incText}>
              NFTainment, an NFT consulting and IP-based NFT E2E service
            </div>
          </div>
          <div className={style.incContent}>
            <div className={style.incSvgWrap}>
              <div className={style.incContentItemLine2}></div>
              <KnowYourSelfLogo />
            </div>
            <div className={style.incText}>
              NFTainment, an NFT consulting and IP-based NFT E2E service
            </div>
          </div>
        </div>
      </article>
      <img src="https://www.nftainment.ai/image/nftchart.png" alt="chart" />
    </section>
  );
};

export default MobileInc;
