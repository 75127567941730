import style from "./style.module.scss";
import { useCurrentLocale } from "../../lib/function";

interface ProgressBoxProps {
  header: string;
  list: JSX.Element[];
}
const ProgressBox = (props: ProgressBoxProps) => {
  const currentLocale = useCurrentLocale();
  return (
    <div className={style.progressBoxWrap}>
      <div className={style.progressBoxHeader}>{props.header}</div>
      <div
        className={
          currentLocale === "ko"
            ? style.progressBoxListKo
            : style.progressBoxList
        }
      >
        <ul>
          {props.list.map((el, index) => (
            <li key={index}>{el}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProgressBox;
