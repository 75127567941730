import { useEffect, useState } from "react";
import MenuIcon from "../../../Icon/menu/MenuIcon";
import { HeaderProps } from "../../../Type/type";
import SideMenu from "../../sideMenu/SideMenu";
import style from "./style.module.scss";
import TranslateIcon from "../../../Icon/translate/TranslateIcon";
import { setCurrentLocale } from "../../../store";
import { useDispatch } from "react-redux";
import { useCurrentLocale } from "../../../lib/function";

const MobileHeader = (props: HeaderProps) => {
  const dispatch = useDispatch();
  const currentLocale = useCurrentLocale();
  const [isMenuState, setIsMenuState] = useState(false);

  const isMenuHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsMenuState((prev) => !prev);
    event.stopPropagation();
  };

  const languageChangeToEn = () => {
    dispatch(setCurrentLocale("en"));
  };

  const languageChangeToKo = () => {
    dispatch(setCurrentLocale("ko"));
  };

  const isEnSelected =
    currentLocale === "en" ? "isSelectedItem" : "isNotSelected";

  const isKoSelected =
    currentLocale === "ko" ? "isKorSelectedItem" : "isKorNotSelectedItem";

  useEffect(() => {
    const handleDocumentClick = () => {
      setTimeout(() => {
        setIsMenuState(false);
      }, 700);
    };
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  return (
    <header className={style.mobileHeaderWrap}>
      <div className={style.mobileContainer}>
        <div className={style.mobilelogoWrap}>
          <div className={style.menuIcon} onClick={props.onModalOpener}>
            <MenuIcon />
          </div>
          <div className={style.mobileHeaderLogo}>
            <img
              src="https://www.nftainment.ai/image/mobileheaderlogo.png"
              alt="logo"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            />
          </div>
        </div>
        <div className="headerLanguageChange">
          <div
            className="languageChangeWrap"
            onClick={(event) => {
              isMenuHandler(event);
            }}
          >
            <TranslateIcon />
          </div>
          {isMenuState && (
            <div className={`languageMenu ${isMenuState ? "active" : ""}`}>
              <div
                className={isEnSelected}
                onClick={() => languageChangeToEn()}
              >
                ENG
              </div>
              <div
                className={isKoSelected}
                onClick={() => languageChangeToKo()}
              >
                KOR
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
