import { ReactComponent as MarketProject } from "./marketProject.svg";

const MarketProjectIcon = () => {
  return (
    <>
      <MarketProject />
    </>
  );
};

export default MarketProjectIcon;
