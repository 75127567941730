import React, { forwardRef } from "react";
import { AppProps } from "../../Type/type";
import { Breakpoints } from "../../component/breakpoints/Breakpoints";
import Card from "../../component/card/Card";
import Bag from "../../Icon/bag/BagIcon";
import BussinessIcon from "../../Icon/bussiness/BussinessIcon";
import CheckIcon from "../../Icon/check/Check";
import PersonIcon from "../../Icon/person/PersonIcon";
import style from "./style.module.scss";
import TabletCompany from "./tabletCompany/TabletCompany";
import WebCompany from "./webCompany/WebCompany";
import Locale from "../../component/locale/Locale";
import {useCurrentLocale} from "../../lib/function";

const CompanyInfo = forwardRef<HTMLDivElement, AppProps>((props, ref) => {
  const currentLocale = useCurrentLocale();
  const prefixRenderer = () => {
    if (props.prefix) {
      switch (props.prefix) {
        case "xxl":
          return <WebCompany prefix = {props.prefix}/>;
        case "xl":
          return <WebCompany prefix = {props.prefix}/>;
        case "md":
          return <TabletCompany prefix={props.prefix} />;
        case "sm":
          return <TabletCompany prefix={props.prefix} />;
        case "xs":
          return <TabletCompany prefix={props.prefix} />;

        default:
          return <WebCompany prefix = {props.prefix}/>;
      }
    }
  };

  return (
    <section ref={ref} className={style.companyInfoWrap}>
      <div className={style.companyInfoTopback}>
        {props.prefix !== "sm" ? (
          <div className={style.companyInfoHeaders}>
            <div className={style.companyInfoHeader1}>Services</div>
            <div className={style.companyInfoHeader2}><Locale tag="nftm_main2_h2"/></div>
            <div className={currentLocale === "ko" ? style.companyInfoDescKo : style.companyInfoDesc}>
              <div>
                <Locale tag="nftm_main2_h3_1"/>
                {
                  currentLocale === "en" &&  <Locale tag="nftm_main2_h3_2"/>
                }
              </div>
              <div>{currentLocale === "en" && <Locale tag="nftm_main2_h3_2"/>}</div>
            </div>
          </div>
        ) : (
          <div className={style.mobileCompanyInfoHeaders}>
            <div className={style.mobileCompanyInfoHeader1}>Services</div>
            <div className={style.mobileCompanyInfoHeader2}>
        {
          currentLocale === "ko" ? <Locale tag="nftm_main2_h2"/>:<><Locale tag="nftm_main2_h2_mobile1"/><br/><Locale tag="nftm_main2_h2_mobile2"/></>
        }
            </div>
            <div className={style.mobileCompanyInfoDesc}>
              <div className={style.mobileCompanyInfoDesc1}>
                <Locale tag="nftm_main2_h3_1_mobile"/>
              </div>
              <div className={style.mobileCompanyInfoDesc2}>
                <Locale tag="nftm_main2_h3_2_mobile"/>
              </div>
              <div className={style.mobileCompanyInfoDesc3}>
                <Locale tag="nftm_main2_h3_3_mobile"/>
              </div>
              <div className={style.mobileCompanyInfoDesc4}>
                {
                  currentLocale === "en" && <Locale tag="nftm_main2_h3_4_mobile"/>
                }
              </div>
            </div>
          </div>
        )}
      </div>
      {prefixRenderer()}
      <div className={style.companyInfoBottomBack}>
        <div className={style.companyInfoCheckList}>
          <div className={style.checkListWrap}>
            <div className={style.checkListContainer}>
              <ul className={style.listWrap}>
                <li className={currentLocale === "ko" ? style.checkListTextKo : style.checkListText}>
                  <Locale tag="nftm_main3_bottom_desc1"/>
                </li>
                <li className={currentLocale === "ko" ? style.checkListTextKo : style.checkListText}>
                  <Locale tag="nftm_main3_bottom_desc2"/>
                  <br />
                  <Locale tag="nftm_main3_bottom_desc3"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CompanyInfo;
