import style from "./style.module.scss";
import CopyIcon from "../../../Icon/copyIcon/CopyIcon";
import QuestionMarkIcon from "../../../Icon/questionMarkIcon/questionMarkIcon";
import UpArrow from "../../../Icon/upArrow/UpArrow";
import CalendarIcon from "../../../Icon/calender/CalendarIcon";
import PolygonIcon from "../../../Icon/polygonIcon/PolygonIcon";
import { ProjectProps } from "../../../lib/type";
const ProjectDetail = (props: ProjectProps) => {
  return (
    <article className={style.projectDetailArticle}>
      <div
        className={style.menuCover}
        onClick={() => {
          props.setIsContentMenuState &&
            props.setIsContentMenuState("customer");
          props.contentMenuHandler && props.contentMenuHandler("customer");
        }}
      ></div>
      <div className={style.finger}>
        <img src={process.env.PUBLIC_URL + "/image/leftfinger.png"} />
      </div>
      <div className={style.projectDetailBanner}></div>
      <div className={style.projectDetailContents}>
        <div className={style.contentHeaderWrap}>
          <div className={style.projectImage}>
            <img
              src="https://www.nftainment.ai/image/jisoo.png"
              alt="projectDetail"
            />
          </div>
          <div className={style.contentInfo}>
            <div className={style.contentTitle}>
              Flower Serenade: Fan Meet Participation Pass
            </div>
            <div className={style.contentDetail}>
              <div className={style.contentDetailTitle}>ABCDE 고객사</div>
              <div className={style.contentDetailContent}>
                0x0a...08ec
                <CopyIcon />
              </div>
              <div className={style.contentDetailRoyalty}>
                창작자 로열티 10% <QuestionMarkIcon />
              </div>
            </div>
            <div className={style.contentDetailDesc}>
              블랙핑크 지수의 ME 앨범 발매 기념으로 진행되는 NFT 프로젝트입니다.
              팬들과 아티스트 간의 교류와 상호작용을 촉진하고, 그룹과 팬들 간의
              특별한 연결을 형성합니다. 이 NFT를 소유한 팬들은 그룹과의 더욱
              깊은 관계를 형성하며, 독점적인 경험을 즐길 수 있습니다. NFT를
              소유한 팬들은 그룹의 공식 팬 미팅에 참여할 수 있는 특별한 기회를
              얻게 됩니다. 이 NFT는 팬들에게 VIP 액세스 권한을 부여하여, 미팅에
              참석할 수 있는 자격과 특전을 제공합니다.
            </div>
          </div>
        </div>
        <div className={style.projectSchedule}>
          <div className={style.projectScheduleWrap}>
            <div className={style.projectScheduleTitle}>
              프로젝트 일정 <UpArrow />
            </div>
            <div className={style.projectScheduleDetail}>
              <div className={style.scheduleWrap}>
                <div className={style.scheduleTitle}>홈페이지 오픈일</div>
                <div className={style.scheduleInfoWrap}>
                  <CalendarIcon />
                  <div className={style.date}>2023.07.01 21:00 (UTC+9)</div>
                </div>
              </div>
              <div className={style.scheduleWrap}>
                <div className={style.scheduleTitle}>1차 판매 일정</div>
                <div className={style.scheduleInfoWrap}>
                  <CalendarIcon />
                  <div className={style.date}>
                    2023.07.01 13:00 ~ 2023.07.20 23:59 (UTC+9)
                  </div>
                </div>
              </div>
              <div className={style.scheduleWrap}>
                <div className={style.scheduleTitle}>2차 거래 일정</div>
                <div className={style.scheduleInfoWrap}>
                  <CalendarIcon />
                  <div className={style.date}>2023.07.21 00:00 (UTC+9)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.chainInfo}>
          <div className={style.chainInfoWrap}>
            <div className={style.chainItemWrap}>
              <div className={style.chainInfoTitle}>체인</div>
              <div className={style.chainInfovalue2}>
                <PolygonIcon />
                Polygon
              </div>
            </div>
            <div className={style.chainItemWrap}>
              <div className={style.chainInfoTitle}>거래량</div>
              <div className={style.chainInfovalue}>6,713 MATIC</div>
            </div>
            <div className={style.chainItemWrap}>
              <div className={style.chainInfoTitle}>최저판매가</div>
              <div className={style.chainInfovalue}>100 MATIC</div>
            </div>
            <div className={style.chainItemWrap}>
              <div className={style.chainInfoTitle}>아이템</div>
              <div className={style.chainInfovalue}>500</div>
            </div>
            <div className={style.chainItemWrap}>
              <div className={style.chainInfoTitle}>소유자</div>
              <div className={style.chainInfovalue}>432</div>
            </div>
          </div>
        </div>
        <div className={style.dealContainer}>
          <img
            className={style.graph}
            src={process.env.PUBLIC_URL + "/image/dealgraph.png"}
            alt="graph"
          />
          <img
            className={style.info}
            src={process.env.PUBLIC_URL + "/image/dealinfo.png"}
          />
        </div>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/image/salesregcomplete.png"}
            alt="deal"
          />
        </div>
      </div>
    </article>
  );
};

export default ProjectDetail;
