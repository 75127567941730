import { forwardRef } from "react";
import CheckList from "../../component/checkList/CheckList";
import { AppProps } from "../../Type/type";
import style from "./style.module.scss";
import Locale from "../../component/locale/Locale";

const desc1 = [
  <Locale tag="nftm_main4_solution_list1" />,
  <Locale tag="nftm_main4_solution_list2" />,
  <Locale tag="nftm_main4_solution_list3" />,
];

const desc2 = [
  <Locale tag="nftm_main4_solution_list4" />,
  <Locale tag="nftm_main4_solution_list5" />,
  <Locale tag="nftm_main4_solution_list6" />,
];

const Works = forwardRef<HTMLDivElement, AppProps>((props, ref) => (
  <section className={style.workWrap} ref={ref}>
    <div className={style.workContainer}>
      <header className={style.workHeaders}>
        <h1 className={style.workHeaders1}>NaaS</h1>
        <h1 className={style.workHeaders2}>NFT as a Service</h1>
        <p className={style.workHeaders3}>
          <Locale tag="nftm_main4_h2" />
        </p>
      </header>
      <div className={style.workContentsWrap}>
        <div className={style.workContentsContainer}>
          <div className={style.workText}>
            <h3 className={style.workTextHeader}>NFT Solutions</h3>
            <CheckList desc={desc1} circle="Solution" />
            <CheckList desc={desc2} circle="Infra" />
          </div>
          <div className={style.imageWrap}>
            <img src="https://www.nftainment.ai/image/nft3d.png" alt="3d" />
          </div>
        </div>
      </div>
    </div>
  </section>
));

export default Works;
