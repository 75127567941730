import { ReactComponent as QuestionMark } from "./questionMark.svg";

const QuestionMarkIcon = () => {
  return (
    <>
      <QuestionMark />
    </>
  );
};

export default QuestionMarkIcon;
