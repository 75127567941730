import { ReactComponent as VIcon } from "./VIcon.svg";

const VIcons = () => {
  return (
    <div>
      <VIcon />
    </div>
  );
};

export default VIcons;
