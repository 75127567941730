import { ReactComponent as EyesOpen } from "./EyesOpen.svg";

const EyesOpenIcon = () => {
  return (
    <>
      <EyesOpen />
    </>
  );
};

export default EyesOpenIcon;
