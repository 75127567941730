import { ReactComponent as CompanyProfile } from "./CompanyProfile.svg";

const CompanyProfileIcon = () => {
  return (
    <>
      <CompanyProfile />
    </>
  );
};

export default CompanyProfileIcon;
