
import { forwardRef } from "react"
import { AppProps } from "../../Type/type"
import BitbuleInc from "./component/bitblueinc/BitblueInc"
import Join from "./component/join/Join"
import Leadership from "./component/leadership/Leadership"
import Vision from "./component/vision/Vision"
import style from "./style.module.scss"

const CompanyLeadership = forwardRef<HTMLDivElement, AppProps>((props, ref) => (
     <section className={style.companyLeadershipBack}>
        <BitbuleInc prefix={props.prefix} ref={ref}/>
        <Vision prefix={props.prefix} />
        <Leadership prefix={props.prefix} />
        <Join/>
    </section>
))

export default CompanyLeadership