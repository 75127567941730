import Card from "../../../component/card/Card"
import BagIcon from "../../../Icon/bag/BagIcon"
import BussinessIcon from "../../../Icon/bussiness/BussinessIcon"
import PersonIcon from "../../../Icon/person/PersonIcon"
import style from "./style.module.scss"
import Locale from "../../../component/locale/Locale";

const WebCompany = (props: {prefix: string}) => {
  return         <div className={style.companyInfoCards}>
  <Card h1="Enterprise" h2={<Locale tag="nftm_main3_card_h2_1"/>} desc={<Locale tag="nftm_main3_card_desc1"/>} variant="blue" icon={<BagIcon prefix={props.prefix}/>} />
  <Card h1="Private" h2={<Locale tag="nftm_main3_card_h2_2"/>} desc={<Locale tag="nftm_main3_card_desc2"/>} variant="green" icon={<PersonIcon prefix={props.prefix}/>} />
  <Card h1="IP, Content" h2={<Locale tag="nftm_main3_card_h2_3"/>} desc={<Locale tag="nftm_main3_card_desc3"/>}variant="purple" icon={<BussinessIcon prefix={props.prefix}/>} />
</div>
}

export default WebCompany