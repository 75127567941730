import style from "./style.module.scss";
import { ProjectProps } from "../../../lib/type";

const Registration = (props: ProjectProps) => {
  return (
    <article className={style.projectRegArticle}>
      <div className={style.projectRegWrap}>
        <div className={style.projectRegSideContent}>
          <div className={style.projectRegSideHeader}>신규 프로젝트 등록</div>
          <div className={style.projectRegSideContentImageWrap}>
            <div className={style.projectRegSideContentImageDesc}>
              프로젝트 대표이미지
            </div>
            <div className={style.projectRegSideContentImage}>
              <img
                src="https://www.nftainment.ai/image/jisoo.png"
                alt="project"
              />
            </div>
          </div>
        </div>
        <div className={style.projectRegMainContent}>
          <img
            src="https://www.nftainment.ai/image/projectRegInput.jpeg"
            alt="project"
          />
          <div className={style.projectRegInputBtnWrap}>
            <button className={style.projectRegInputBtnCancel}>취소</button>
            <button
              className={style.projectRegInputBtnSave}
              onClick={() => {
                props.setIsContentMenuState &&
                  props.setIsContentMenuState("projectManage");
                props.contentMenuHandler &&
                  props.contentMenuHandler("projectManage");
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
      <div className={style.finger}>
        <img
          src={process.env.PUBLIC_URL + "/image/leftfinger.png"}
          alt="finger"
        />
      </div>
    </article>
  );
};

export default Registration;
