import { ReactComponent as Danger } from "./Danger.svg";

const DangerIcon = () => {
  return (
    <>
      <Danger />
    </>
  );
};

export default DangerIcon;
