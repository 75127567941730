import { AppProps } from '../../Type/type';
import {ReactComponent as Bussiness} from './bussinessIdea.svg';
import {ReactComponent as MobileBussiness} from './mobileBussinessIdea.svg';
export type IconProps = {
    prefix?: string;
}

const BussinessIcon = (props: AppProps) => {
  return <>
    {
        props.prefix && props.prefix !== "sm" ? <Bussiness/> : <MobileBussiness/>
    }
</>
}

export default BussinessIcon
