import { forwardRef } from "react";
import KnowYourSelfLogo from "../../../../Icon/knowlogo/KnowYourSelfLogo";
import NftainmentLogo from "../../../../Icon/nftainmentlogo/Nftainmentlogo";
import { AppProps } from "../../../../Type/type";
import MobileInc from "./mobileInc/MobileInc";
import style from "./style.module.scss";
import WebInc from "./webInc/WebInc";

const isMobileRenderer = (prefix: string) => {
  switch (prefix) {
    case "xxl":
      return (
       <WebInc/>
      );
    case "xl":
      return (
        <WebInc/>
      );
    case "md":
      return (
        <MobileInc/>
      );
    case "sm":
      return (
        <MobileInc/>
      );
    default:
      return (
        <MobileInc/>
      );
  }
};

const BitbuleInc = forwardRef<HTMLDivElement, AppProps>((props, ref) => (
  <div ref={ref}>
    {
      props.prefix && isMobileRenderer(props.prefix)
    }
  </div>
));

export default BitbuleInc;
