import style from "./style.module.scss";
import NftainmentLogoBlack from "../../../Icon/nftainmentlogo/NftainmentLogoBlack";
import InputLabel from "../../../component/inputLable/InputLabel";
import { useEffect, useState } from "react";
import EyesOpenIcon from "../../../Icon/eyes/EyesOpen";
import EyesCloseIcon from "../../../Icon/eyes/EyesClose";
import { useNavigate } from "react-router-dom";
import FingerIcon from "../../../Icon/fingerIcon/FingerIcon";
import { useDispatch } from "react-redux";
import { login } from "../../../store";

const NftainmentLogin = () => {
  const [isEyesOpen, setEyesOpen] = useState(false);
  const [isPasswordMessage, setIsPasswordMessage] = useState("");
  const [isPassword, setIsPassword] = useState("");
  const isBitBluePassword = "bitblue1115!";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const passwordCheck = () => {
    if (isPassword === isBitBluePassword) {
      dispatch(login());
      navigate("/demo");
    } else if (isPasswordMessage.length === 0) {
      setIsPasswordMessage("비밀번호를 입력해 주세요");
    } else if (isPasswordMessage !== isBitBluePassword) {
      setIsPasswordMessage("비밀번호가 일치하지 않습니다.");
    }
  };
  return (
    <section className={style.loginContainer}>
      <header className={style.loginHeader}>
        <NftainmentLogoBlack />
      </header>
      <article className={style.loginArticle}>
        <div className={style.loginContent}>
          <div className={style.loginContentInner}>
            <div className={style.loginContentInnerHeader}>
              <div className={style.loginContentInnerHeaderTitle}>로그인</div>
              <div className={style.loginContentInnerHeaderSubTitle}>
                이메일 주소로 로그인해주세요
              </div>
            </div>
            <div className={style.loginContentInnerInputs}>
              <InputLabel label="이메일">
                <div className={style.loginContentInnerInputWrap}>
                  <input
                    className={style.loginContentInnerInput}
                    value="admin"
                  />
                </div>
              </InputLabel>
              <InputLabel label="비밀번호 ">
                <div className={style.loginContentInnerInputWrap}>
                  <input
                    className={style.loginContentInnerInput}
                    type={isEyesOpen ? "text" : "password"}
                    value={isPassword}
                    onChange={(e) => setIsPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        passwordCheck();
                      }
                    }}
                  />
                  {isEyesOpen ? (
                    <div
                      className={style.loginContentInnerInputEye}
                      onClick={() => {
                        setEyesOpen(false);
                      }}
                    >
                      <EyesOpenIcon />
                    </div>
                  ) : (
                    <div
                      className={style.loginContentInnerInputEye}
                      onClick={() => {
                        setEyesOpen(true);
                      }}
                    >
                      <EyesCloseIcon />
                    </div>
                  )}
                </div>
              </InputLabel>
            </div>
            <div className={style.loginContentInnerLoginBtnWrap}>
              <button
                className={style.loginContentInnerLoginBtn}
                onClick={() => {
                  passwordCheck();
                }}
              >
                로그인
              </button>
              <div className={style.loginContentInnerForgotAccount}>
                <div className={style.loginContentInnerForgotAccountTitle}>
                  계정을 잊으셨나요 ?
                </div>
                <div className={style.loginContentInnerForgotAccountSubTitle}>
                  계정찾기
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.loginFooter}>
          Copyright ©bitBLUE Corp. All Rights Reserved.
        </div>
      </article>
    </section>
  );
};

export default NftainmentLogin;
