import style from "./style.module.scss";
import CompanyProfileIcon from "../../../Icon/companyProfile/CompanyProfile";
import DownArrow from "../../../Icon/downArrow/DownArrow";
import React, { useState } from "react";
import ProfileDropDown from "./customerMarketComponent/dropDownMenu/DropDown";
import { DemoProps } from "../../../lib/type";

const CustomerMarketPlace = (props: DemoProps) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  return (
    <section className={style.marketSection}>
      <header className={style.marketHeader}>
        <div className={style.headerItems}>
          <div className={style.headerText}>
            <div>Home</div>
            <div>Stories</div>
            <div>Roadmap</div>
            <div>FAQ</div>
            <div>Pricing</div>
          </div>
          <div
            className={style.headerProfile}
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
          >
            {!isDropDownOpen && (
              <div className={style.finger}>
                <img
                  src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                  alt="finger"
                />
              </div>
            )}
            <CompanyProfileIcon />
            <DownArrow />
          </div>
          {
            <ProfileDropDown
              isOpen={isDropDownOpen}
              setHeaderSubNavItem={props.setHeaderSubNavItem}
              setHeaderNavItem={props.setHeaderNavItem}
              setIsContentMenuState={props.setIsContentMenuState}
              isContentMenuState={props.isContentMenuState}
              isHeaderNavItem={props.isHeaderNavItem}
              isHeaderSubNavItem={props.isHeaderSubNavItem}
              contentMenuHandler={props.contentMenuHandler}
              contentRender={props.contentRender}
              isHeaderChildNavItem={props.isHeaderChildNavItem}
              setHeaderChildNavItem={props.setHeaderChildNavItem}
            />
          }
        </div>
      </header>
      <div className={style.space}></div>
      <article className={style.jisooBanner}></article>
      <main className={style.mainContent}>
        <div className={style.flowerSerenade}>
          <div className={style.flowerSerenadeTextWrap}>
            <div className={style.flowerSerenadeTitle}>Flower Serenade</div>
            <div className={style.flowerSerenadeTextDesc}>
              블랙핑크 지수의 ME 앨범 발매 기념으로 진행되는 NFT 프로젝트입니다.
              <br />
              팬들과 아티스트 간의 교류와 상호작용을 촉진하고, 그룹과 팬들 간의
              특별한 연결을 형성합니다.
              <br />
              이 NFT를 소유한 팬들은 그룹과의 더욱 깊은 관계를 형성하며,
              독점적인 경험을 즐길 수 있습니다.
              <br />
              NFT를 소유한 팬들은 그룹의 공식 팬 미팅에 참여할 수 있는 특별한
              기회를 얻게 됩니다.
              <br />이 NFT는 팬들에게 VIP 액세스 권한을 부여하여, 미팅에 참석할
              수 있는 자격과 특전을 제공합니다.
            </div>
            <button className={style.connectedBtn}>{`Get Connected ->`}</button>
          </div>
          <div className={style.jisooCardWrap}>
            <img
              src="https://www.nftainment.ai/image/jisooCard.png"
              alt="customerMarket"
            />
          </div>
        </div>
        <div className={style.jisooBenefit}>
          <div className={style.jisooBenefitWrap}>
            <div className={style.jisooHill}>
              <img
                src="https://www.nftainment.ai/image/jisooHill.png"
                alt="customerMarket"
              />
            </div>
            <div className={style.jisooBenefitInfo}>
              <div className={style.benefitHeader}>
                <div className={style.benefitTitle}>HOLDER BENEFIT</div>
                <div className={style.benefitDesc}>홀더 혜택 안내</div>
              </div>
              <div className={style.benefitDetails}>
                <img
                  src="https://www.nftainment.ai/image/jisooBenefit1.png"
                  alt="customerMarket"
                />
                <img
                  src="https://www.nftainment.ai/image/jisooBenefit2.png"
                  alt="customerMarket"
                />{" "}
                <img
                  src="https://www.nftainment.ai/image/jisooBenefit3.png"
                  alt="customerMarket"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.schedule}>
          <div className={style.scheduleWrap}>
            <img
              src="https://www.nftainment.ai/image/schedule.png"
              alt="customerMarket"
            />
            <img
              src="https://www.nftainment.ai/image/jisooSchedule.png"
              alt="customerMarket"
            />
          </div>
        </div>
        <div className={style.roadmap}>
          <div className={style.roadmapWrap}>
            <div className={style.roadmapHeader}>ROADMAP</div>
            <div className={style.roadmapImage}>
              <img
                src="https://www.nftainment.ai/image/roadmap.png"
                alt="customerMarket"
              />
            </div>
          </div>
        </div>
        <div className={style.partnerCompany}>
          <div className={style.partnerCompanyWrap}>
            <div className={style.partnerCompanyHeader}>PARTNER</div>
            <div className={style.partnerCompanyImage}>
              <img
                src="https://www.nftainment.ai/image/partnerCompany.png"
                alt="customerMarket"
              />
            </div>
          </div>
        </div>
        <div className={style.singleAlbum}>
          <div className={style.singleAlbumWrap}>
            <div className={style.singleAlbumHeader}>
              <div className={style.singleAlbumsubTitle}>
                the power to do more
              </div>
              <div className={style.singleAlbumTitle}>Flower Serenade</div>
              <button
                className={style.getBtn}
              >{`Get Participation Pass ->`}</button>
              <div className={style.singleAlbumsubDesc}>
                no credit card required.
              </div>
            </div>
            <div className={style.singleAlbumImg}>
              <img
                src="https://www.nftainment.ai/image/singleAlbum.png"
                alt="customerMarket"
              />
            </div>
          </div>
        </div>
      </main>
      <div className={style.footer}>
        <div className={style.footerWrap}>
          <div className={style.footerTitleWrap}>
            <div className={style.footerTitle}>FAQ</div>
            <div className={style.footerDesc}>자주 묻는 질문</div>
          </div>
          <div className={style.footerImage}>
            <img
              src="https://www.nftainment.ai/image/footerImage.png"
              alt="customerMarket"
            />
          </div>
        </div>
      </div>
      <div className={style.madeBy}>
        <div className={style.madeByWrap}>
          <img
            src="https://www.nftainment.ai/image/footer.png"
            alt="customerMarket"
          />
        </div>
      </div>
    </section>
  );
};

export default CustomerMarketPlace;
