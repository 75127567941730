import { ReactComponent as DealPrice } from "./DealPrice.svg";

const DealPriceIcon = () => {
  return (
    <>
      <DealPrice />
    </>
  );
};

export default DealPriceIcon;
