import { ReactComponent as Bells } from "./Bells.svg";

const BellsIcon = () => {
  return (
    <>
      <Bells />
    </>
  );
};

export default BellsIcon;
