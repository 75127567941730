import { AppProps } from "../../../../Type/type";
import style from "./style.module.scss";
import Locale from "../../../../component/locale/Locale";
import {useCurrentLocale} from "../../../../lib/function";

const Vision = (props: AppProps) => {
    const currentLocale = useCurrentLocale()
  return (
    <header className={style.visionback}>
      <div className={style.headerWrap}>
        <h1 className={style.header1}>Vision</h1>
        <div className={currentLocale === "ko" ? style.header2Ko : style.header2}>
          <div>{<Locale tag="nftm_main_vision_text1"/>}</div><div><Locale tag="nftm_main_vision_text2"/></div><div><Locale tag="nftm_main_vision_text3"/></div>
        </div>
        <h2 className={style.header3}>{currentLocale === "ko" ? <Locale tag="nftm_main_vision_text5"/> : <Locale tag="nftm_main_vision_text4"/> }</h2>
      </div>
    </header>
  );
};

export default Vision;
