import KnowYourSelfLogo from "../../../../../Icon/knowlogo/KnowYourSelfLogo";
import NftainmentLogo from "../../../../../Icon/nftainmentlogo/Nftainmentlogo";
import style from "./style.module.scss";
import Locale from "../../../../../component/locale/Locale";
import { useCurrentLocale } from "../../../../../lib/function";

const WebInc = () => {
  const currentLocale = useCurrentLocale();
  return (
    <section className={style.incWrap}>
      <div className={style.incContainer}>
        <header className={style.incDescWrap}>
          <h1 className={style.descItem1}>Company</h1>
          <h2 className={style.descItem2}>bitBLUE Inc.</h2>
          <div className={style.incDescSmalltextWrap}>
            <div className={style.incDescSmalltextItem1}>
              <p>bitBLUE</p>
              <p>
                <Locale tag="nftm_main6_desc1" />
              </p>
            </div>
            <p className={style.incDescSmalltextItem2}>
              {currentLocale === "en" && <Locale tag="nftm_main6_desc2" />}
            </p>
          </div>
        </header>
        <div className={style.incContentWrap}>
          <div className={style.incContentContainer}>
            <div className={style.incContentItemWrap}>
              <div className={style.incContentItemLine}></div>
              <div className={style.incContentItemIcon}>
                <NftainmentLogo />
              </div>
              <div className={style.incContentItemText}>
                NFTainment, an NFT consulting and IP-based NFT E2E service
              </div>
            </div>
            <div className={style.incContentItemWrap}>
              <div className={style.incContentItemLine2}></div>
              <div className={style.incContentItemIcon}>
                <KnowYourSelfLogo />
              </div>
              <div className={style.incContentItemText}>
                Know-Yourself, Web3 profile service through personal value
                indicators
              </div>
              <div className={style.nftChart}>
                <img
                  src="https://www.nftainment.ai/image/nftchart.png"
                  alt="NFT chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebInc;
