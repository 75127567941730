import { FunctionComponent, ReactNode } from "react";
import React from "react";
import "./style.scss";
import { classnames } from "../../lib/class";

interface InputLineProps {
  readonly?: boolean;

  children: ReactNode;
  label?: ReactNode;
  right?: ReactNode;
  footer?: ReactNode;
}

const InputLine: FunctionComponent<InputLineProps> = (props) => {
  return (
    <div
      className={classnames(
        "InputLine",
        props.readonly && "ReadOnly",
        !props.label && !props.right && "EmptyLabel"
      )}
    >
      {(props.label || props.right) && (
        <div className="InputLineHeader">
          <label className="InputLineLabel">{props.label}</label>
          {props.right && <span className="InputLineRight">{props.right}</span>}
        </div>
      )}
      <div className="InputLineBody">{props.children}</div>
      {props.footer && <div className="InputLineFooter">{props.footer}</div>}
    </div>
  );
};

export default InputLine;
