import { AppProps } from "../../Type/type";
import Buttons from "../../component/buttons/Buttons";
import MobileSizeService from "./components/mobileService/MobileSizeService";
import TabletService from "./components/tabletService/TabletService";
import WebSizeService from "./components/webService/WebSizeService";
import style from "./style.module.scss";

export type ServiceProps = {
  prefix?: string | undefined;
  onMove: () => void;
};

const Service = (props: ServiceProps) => {
  const isPrefixRenderer = () => {
    if (props.prefix) {
      switch (props.prefix) {
        case "xxl":
          return <WebSizeService onMove={props.onMove} />;
        case "xl":
          return <WebSizeService onMove={props.onMove} />;
        case "md":
          return <TabletService onMove={props.onMove} />;
        case "sm":
          return <MobileSizeService onMove={props.onMove} />;

        default:
          return <WebSizeService onMove={props.onMove} />;
      }
    }
  };

  const imgDummy = [
    "/image/nemoz.png",
    "/image/sment.png",
    "/image/jypent.png",
    "/image/astory.png",
    "/image/mega.png",
    "/image/komca.png",
  ];

  const isMobile = props.prefix !== "sm";
  return (
    <section>
      <div className={style.serviceWrap}>{isPrefixRenderer()}</div>
    </section>
  );
};

export default Service;
