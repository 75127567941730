const RightArrowSilver = () => {
  return (
    <>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.53585 7L0.29285 1.72124C-0.0976156 1.32748 -0.0976165 0.689074 0.292849 0.295317C0.683314 -0.0984392 1.31638 -0.0984391 1.70685 0.295318L7.65674 6.28693C8.04727 6.68075 8.04727 7.31925 7.65674 7.71307L1.70685 13.7047C1.31638 14.0984 0.683314 14.0984 0.292849 13.7047C-0.0976164 13.3109 -0.0976162 12.6725 0.292849 12.2788L5.53585 7Z"
          fill="#A1A1AA"
          fill-opacity="0.3"
        />
      </svg>
    </>
  );
};

export default RightArrowSilver;
