import BigLineIcon from "../../../Icon/bigLine/BigLine";
import FacebookIcon from "../../../Icon/snsIcon/facebook/FacebookIcon";
import InstagramIcon from "../../../Icon/snsIcon/instagram/InstagramIcon";
import TwitterIcon from "../../../Icon/snsIcon/twitter/TwitterIcon";
import YoutubeIcon from "../../../Icon/snsIcon/youtube/YoutubeIcon";
import style from "./style.module.scss";

const WebFooter = () => {
  const iconArray = [
    <YoutubeIcon />,
    <InstagramIcon />,
    <TwitterIcon />,
    <FacebookIcon />,
  ];
  return (
    <footer className={style.footerWrap}>
      <div className={style.logo}>
        <img
          src="https://www.nftainment.ai/image/nftainmentlogo.png"
          alt="logo"
        />
      </div>
      <div className={style.footerContainer}>
        <div className={style.companyContect}>
          <div className={style.companyContectText}>
            <div className={style.companyContectHeader}>
              <div>the Web3 culture</div>
              <div>Company</div>
            </div>
            <div className={style.companyContectSub}>
              <p>contact@bitblue.team</p>
            </div>
          </div>
          <div className={style.companyIcon}>
            {iconArray.map((el, index) => (
              <div className={style.circle} key={index}>
                {el}
              </div>
            ))}
          </div>
        </div>
        <div className={style.companyInfo}>
          <div className={style.infoList}>
            <h4>Quick Links</h4>
            <div>bitBLUE</div>
            <div>Announcement</div>
            <div>Privacy Podivcy</div>
            <div>FAQ</div>
          </div>
          <div className={style.infoList}>
            <h4>Others</h4>

            <div>NFT-Service</div>
            <div>How to Video's</div>
            <div>Get Listed</div>
            <div>Affidivate</div>
          </div>
          <div className={style.infoList}>
            <h4>About Us</h4>
            <div>Terms & Conditions</div>
            <div>Disclaimer</div>
            <div>Imprint</div>
            <div>Contact Us</div>
          </div>
        </div>
      </div>
      <BigLineIcon />
      <div className={style.author}>
        <div className={style.authorChild1}>
          <p>NFTainment and NFT Solutions</p>
        </div>
        <div className={style.authorChild2}>
          <p>Copyrights 2023 bitBLUE Inc. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default WebFooter;
