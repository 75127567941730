import style from "./style.module.scss";
import React from "react";
import MarketProjectIcon from "../../../../../Icon/customerMarketplaceIcon/marketProjectIcon";
import RightColoredIcon from "../../../../../Icon/customerMarketplaceIcon/RightColoredIcon";
import LoakIcons from "../../../../../Icon/customerMarketplaceIcon/LockIcon";
import ExitIcon from "../../../../../Icon/customerMarketplaceIcon/ExitIcon";
import { useNavigate } from "react-router-dom";
import CompanyProfileIcon from "../../../../../Icon/companyProfile/CompanyProfile";
import { DemoProps } from "../../../../../lib/type";

interface ProfileDropDownProps extends DemoProps {
  isOpen: boolean;
}

const ProfileDropDown = (props: ProfileDropDownProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        props.isOpen ? style.profileDropDown : style.profileDropDownClose
      }
    >
      <div className={style.profileDropDownWrap}>
        <div className={style.dropDownProfileWrap}>
          <CompanyProfileIcon />
          <div className={style.propfileInfo}>
            <div className={style.profileInfoName}>Admin</div>
            <div className={style.profileInfoEmail}>Admin.io</div>
          </div>
        </div>
        <div className={style.profileDropDownContainer2} onClick={() => {}}>
          <div className={style.profileDropDownIcon}>
            <MarketProjectIcon />
          </div>
          <div
            className={style.profileDropDownText}
            onClick={() => {
              props.setIsContentMenuState &&
                props.setIsContentMenuState("projectDetail");
              props.contentMenuHandler("projectDetail");
              navigate("/demo");
            }}
          >
            <div className={style.profileDropDownName}>프로필 조회</div>
            <div className={style.finger}>
              <img
                src={process.env.PUBLIC_URL + "/image/rightfinger.png"}
                alt="finger"
              />
            </div>
            <div className={style.profileDropDownRightIcon}>
              <RightColoredIcon color="white" />
            </div>
          </div>
        </div>
        <div className={style.profileDropDownContainer}>
          <div className={style.profileDropDownIcon}>
            <LoakIcons />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>어드민 대시보드</div>
            <div className={style.profileDropDownRightIcon}>
              <RightColoredIcon color="#FFFFFF4D" />
            </div>
          </div>
        </div>
        <div className={style.profileDropDownContainer}>
          <div className={style.profileDropDownIcon}>
            <ExitIcon />
          </div>
          <div className={style.profileDropDownText}>
            <div className={style.profileDropDownName}>로그아웃</div>
            <div className={style.profileDropDownRightIcon}>
              <RightColoredIcon color="#DC3D3D" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropDown;
