import { ReactComponent as Close } from "./closeiconDark.svg";

const CloseIcon = () => {
  return (
    <>
      <Close />
    </>
  );
};

export default CloseIcon;
