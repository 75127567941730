import { ReactComponent as LoakIcon } from "./lockIcon.svg";

const LockIcons = () => {
  return (
    <>
      <LoakIcon />
    </>
  );
};

export default LockIcons;
