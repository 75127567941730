import style from "./style.module.scss";
import { DashboardBoxProps } from "../../lib/type";

const DashboardBox = (props: DashboardBoxProps) => {
  return (
    <div>
      <div className={style.dashboardContainer}>
        <div className={style.dashboardWrap}>
          <div className={style.boxHeader}>
            <div>{props.title}</div>
            {props.icon}
          </div>
          <div className={style.line}></div>
          <div className={style.boxContent}>
            <div className={style.contentLine}>
              <div className={style.item1}>{props.line1item1}</div>
              <div className={style.itemsWrap}>
                <div className={style.item2}>{props.line1item2}</div>
                <div className={style.item3}>{props.line1item3}</div>
              </div>
            </div>
            <div className={style.contentLine}>
              <div className={style.item1}>{props.line2item1}</div>
              <div className={style.itemsWrap}>
                <div className={style.item2}>{props.line2item2}</div>
                <div className={style.item3}>{props.line2item3}</div>
              </div>
            </div>
            <div className={style.contentLine}>
              <div className={style.item1}>{props.line3item1}</div>
              <div className={style.itemsWrap}>
                <div className={style.item2}>{props.line3item2}</div>
                <div className={style.item3}>{props.line3item3}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBox;
