import { ReactComponent as File } from "./File.svg";
import { MenuIconProps } from "../../lib/type";

const FileIcon = (props: MenuIconProps) => {
  return (
    <>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.414 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H8.414L10.414 2ZM18 8H2V16H18V8ZM18 6V4H9.586L7.586 2H2V6H18Z"
          fill={props.isSelected ? "#ffffff" : "#D1D5DB"}
        />
      </svg>
    </>
  );
};

export default FileIcon;
