import style from "./style.module.scss";

interface ProfileProps {
  url: string;
  name: string;

  position: string;
  sns1: string;
  sns2: string;
  email: string;
  sns1Url: string;
  sns2Url: string;
}

const Profile = (props: ProfileProps) => {
  return (
    <>
      <div className={style.leaderInfoContainer}>
        <div className={style.profileImage}>
          <img src={process.env.PUBLIC_URL + `${props.url}`} />
        </div>
        <div className={style.profileDescWrap}>
          <div className={style.profileName}>{props.name}</div>
          <div className={style.infoWrap}>
            <div className={style.info1}>{props.position}</div>
            <div className={style.info2}>
              <div
                onClick={() => {
                  window.open(props.sns1Url);
                }}
              >
                {props.sns1}
              </div>
              |
              <div
                onClick={() => {
                  window.open(props.sns2Url);
                }}
              >
                {props.sns2}
              </div>
            </div>
            <div className={style.info3}>{props.email}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
