import React, { ReactNode } from "react";
import { classnames } from "../../lib/class";

import "./style.scss";
import {useCurrentLocale} from "../../lib/function";

interface CardProps {
  variant: string;
  icon?: ReactNode;
  iconRound?: string;
  h1:  string;
  h2:  JSX.Element;
  desc:  JSX.Element;
  prefix?: string;
}

const Card = (props: CardProps) => {
  const currentLocale=useCurrentLocale()
  const prefixRenderer = () => {
    const web = (
      <div className="cardWrap">
        <div className="card">
          <div className="headerIconWrap">
            <div className={classnames("header1", props.variant)}>
              {props.h1}
            </div>
            <div className="round">{props.icon}</div>
          </div>
          <div className={classnames("header2" , currentLocale === "ko" && "ko")}>{props.h2}</div>
          <div className={classnames("descWrap" , currentLocale === "ko" && "ko")}>{props.desc}</div>
        </div>
      </div>
    );

    const mobile = (
      <div className="mobileCardWrap">
        <div className="mobileCard">
          <div className="mobileHeaderIconWrap">
            <div className={classnames("mobileHeader1", props.variant)}>
              {props.h1}
            </div>
            <div className="mobileRound">{props.icon}</div>
          </div>
          <div className={classnames("mobileHeader2", currentLocale === "ko" && "ko")}>{props.h2}</div>
          <div className={classnames("mobileDescWrap2", currentLocale === "ko" && "ko")}>{props.desc}</div>
        </div>
      </div>
    );
    if (!props.prefix || props.prefix !== "sm") return web;

    if (props.prefix && props.prefix === "sm") return mobile;
  };

  return <div>{prefixRenderer()}</div>;
};

export default Card;
