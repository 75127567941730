import style from "./style.module.scss";
import DashboardBox from "../../../component/dashboardBox/DashboardBox";
import SalesIcon from "../../../Icon/sales/SalesIcon";
import SalesPriceIcon from "../../../Icon/salesPrice/SalesPriceIcon";
import DealPriceIcon from "../../../Icon/dealPrice/DealPriceIcon";
import DealIcon from "../../../Icon/deal/DealIcon";
import RightArrowIcon from "../../../Icon/rightArrow/RightArrow";
import { ProjectProps } from "../../../lib/type";
const Dashboard = (props: ProjectProps) => {
  return (
    <article className={style.dashboardArticle}>
      <div
        className={style.menuOverWrap}
        onClick={() => {
          props.setIsContentMenuState && props.setIsContentMenuState("project");
          props.contentMenuHandler && props.contentMenuHandler("project");
        }}
      ></div>
      <div className={style.dashboardRightContainer}>
        <div className={style.finger}>
          <img src={process.env.PUBLIC_URL + "/image/leftfinger.png"} />
        </div>
        <div className={style.dashboardRightWrap}>
          <div className={style.dashboardBoxContainer}>
            <DashboardBox
              title="판매량"
              icon={<SalesIcon />}
              line1item1="총 판매량"
              line1item2="100"
              line1item3="건"
              line2item1="Opensea"
              line2item2="98"
              line2item3="건"
              line3item1="Marketplace"
              line3item2="2"
              line3item3="건"
            />
            <DashboardBox
              title="판매대금"
              icon={<SalesPriceIcon />}
              line1item1="총 판매대금"
              line1item2="786,424"
              line1item3="MATIC"
              line2item1="Opensea"
              line2item2="700,424"
              line2item3="MATIC"
              line3item1="Marketplace"
              line3item2="86,424"
              line3item3="MATIC"
            />
          </div>
          <div className={style.dashboardBoxContainer}>
            <DashboardBox
              title="거래량"
              icon={<DealIcon />}
              line1item1="총 거래량"
              line1item2="100"
              line1item3="건"
              line2item1="Opensea"
              line2item2="98"
              line2item3="건"
              line3item1="Marketplace"
              line3item2="2"
              line3item3="건"
            />
            <DashboardBox
              title="거래대금"
              icon={<DealPriceIcon />}
              line1item1="총 거래대금"
              line1item2="786,424"
              line1item3="MATIC"
              line2item1="Opensea"
              line2item2="700,424"
              line2item3="MATIC"
              line3item1="Marketplace"
              line3item2="86,424"
              line3item3="MATIC"
            />
          </div>
        </div>
        <div className={style.dashboardRightStatsContainer}>
          <div className={style.dashboardRightStatsWrap}>
            <div className={style.dashboardRightStats}>
              <div>매출 통계</div>
              <RightArrowIcon />
            </div>
            <img src="https://www.nftainment.ai/image/salesStats.png" />
          </div>
        </div>
      </div>
      <div className={style.dashboardLeftContainer}>
        <div className={style.customerSituation}>
          <div className={style.customerSituationWrap}>
            <div className={style.customerSituationTitle}>
              <div>회원 현황</div>
              <RightArrowIcon />
            </div>
            <div className={style.customerSituationContent}>
              <div className={style.customerSituationContentBox}>
                <div className={style.customerSituationContentBoxTitle}>
                  DAU
                </div>
                <div className={style.customerSituationContentBoxValue}>
                  1,589
                </div>
              </div>
              <div className={style.customerSituationContentBox}>
                <div className={style.customerSituationContentBoxTitle}>
                  MAU
                </div>
                <div className={style.customerSituationContentBoxValue}>
                  2,757
                </div>
              </div>
              <div className={style.customerSituationContentBox2}>
                <div className={style.customerSituationContentBoxTitle}>
                  신규회원수
                </div>
                <div className={style.customerSituationContentBoxValue}>10</div>
              </div>
            </div>
          </div>
          <div className={style.customerSituationStatsImage}>
            <img
              src="https://www.nftainment.ai/image/customerStats.png"
              alt="customerStats"
            />
          </div>
        </div>
        <div className={style.nftDealSituation}>
          <div className={style.nftDealSituationWrap}>
            <div className={style.nftDealSituationTitleWrap}>
              <div className={style.nftDealSituationTitle}>NFT거래 현황</div>
              <RightArrowIcon />
            </div>
            <div className={style.nftDealSituationContent}>
              <img
                src="https://www.nftainment.ai/image/nftDealStats.png"
                alt="nftDealStats"
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Dashboard;
