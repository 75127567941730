import style from "./style.module.scss";
import { ProjectProps } from "../../../lib/type";
const Revenue = (props: ProjectProps) => {
  return (
    <article className={style.revenueArticle}>
      <div
        className={style.menuOverWrap}
        onClick={() => {
          props.setIsContentMenuState &&
            props.setIsContentMenuState("dashboard");
          props.contentMenuHandler && props.contentMenuHandler("dashboard");
        }}
      ></div>
      <div className={style.finger}>
        <img
          src={process.env.PUBLIC_URL + "/image/leftfinger.png"}
          alt="finger"
        />
      </div>
      <div className={style.revenueWrap}>
        <div className={style.revenueTitle}>수익 조회</div>
        <div className={style.revenueImage}>
          <img
            src={process.env.PUBLIC_URL + "/image/revenue.png"}
            alt="revenue"
          />
        </div>
      </div>
    </article>
  );
};

export default Revenue;
