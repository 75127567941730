import { ReactComponent as Minting } from "./Minting.svg";
interface MintingProps {
  isReadyToMinting: boolean;
}
const MintingIcon = (props: MintingProps) => {
  return (
    <>
      <svg
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0003 26.8333C6.63633 26.8333 0.666992 20.864 0.666992 13.5C0.666992 6.13601 6.63633 0.166672 14.0003 0.166672C21.3643 0.166672 27.3337 6.13601 27.3337 13.5C27.3337 20.864 21.3643 26.8333 14.0003 26.8333ZM14.0003 24.1667C16.8293 24.1667 19.5424 23.0429 21.5428 21.0425C23.5432 19.0421 24.667 16.329 24.667 13.5C24.667 10.671 23.5432 7.95792 21.5428 5.95753C19.5424 3.95715 16.8293 2.83334 14.0003 2.83334C11.1713 2.83334 8.45824 3.95715 6.45785 5.95753C4.45747 7.95792 3.33366 10.671 3.33366 13.5C3.33366 16.329 4.45747 19.0421 6.45785 21.0425C8.45824 23.0429 11.1713 24.1667 14.0003 24.1667V24.1667ZM14.0003 6.90001L20.6003 13.5L14.0003 20.1L7.40033 13.5L14.0003 6.90001V6.90001ZM14.0003 10.672L11.1723 13.5L14.0003 16.328L16.8283 13.5L14.0003 10.672V10.672Z"
          fill={props.isReadyToMinting ? "#ffffff" : "rgba(26,26,26, 0.5)"}
        />
      </svg>
    </>
  );
};

export default MintingIcon;
