import { useState } from "react";
import { HeaderProps } from "../../Type/type";
import SideMenu from "../sideMenu/SideMenu";
import MobileHeader from "./mobileHeader/MobileHeader";

import WebHeader from "./webHeader/WebHeader";


const Header = (props: HeaderProps) => {


  return (
    <>
      {props.prefix !== "sm" ? (
        <WebHeader onMoveToCompany={props.onMoveToCompany} onMoveToNaas={props.onMoveToNaas} onMoveToService={props.onMoveToService} prefix={props.prefix}/>
      ) : (
        <MobileHeader onMoveToCompany={props.onMoveToCompany} onMoveToNaas={props.onMoveToNaas} onMoveToService={props.onMoveToService} prefix={props.prefix}
        onModalOpener={props.onModalOpener}
        />
      )}
    </>
  );
};

export default Header;
