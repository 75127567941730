import { ReactComponent as Sales } from "./Sales.svg";

const SalesIcon = () => {
  return (
    <>
      <Sales />
    </>
  );
};

export default SalesIcon;
